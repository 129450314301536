<h4 mat-dialog-title>
  確定刪除
</h4>
<div style="padding: 25px 0; font-size: 18px;">
  <p class="m-0">資料一旦刪除，即無法自行恢復！</p>
  <p class="m-0">是否刪除此筆資料？</p>
</div>
<div class="cus-row">
  <div class="col-6 p-0">
    <button type="button" class="btn btn-outline-secondary btn-block" style="border: 0;" (click)="closeDialog(false)">否</button>
  </div>
  <div class="col-6 p-0">
    <button type="button" class="btn btn-outline-danger btn-block" style="border: 0;" (click)="closeDialog(true)">是</button>
  </div>
</div>
