export const appPath = {
  front: {
    home: 'home',
    productList: 'product-list',
    product: 'product',
    cart: 'cart',
    login: 'front-login',
    register: 'front-register',
    memberCenter: 'front-member',
  },

  // 登入
  login: 'login',
  homePage: 'service-menu',
  module_system: 'module-system',
  module_employee: 'module-employee',
  module_member: 'module-member',
  module_product: 'module-product',
  module_telecome: 'module-telecome',
  module_remittance: 'module-remittance',
  module_role: 'module-role'
};
