import { UserService } from './../../../services/user.service';
import { Response } from './../../../models/response';
import { ImgService } from './../../../services/img.service';
import { Component, OnInit, Inject, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from '../../../services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from '../../../../../node_modules/rxjs';
import { MytoastrService } from '../../../services/mytoastr.service';

@Component({
  selector: 'app-cart-list-dialog',
  templateUrl: './cart-list-dialog.component.html',
  styleUrls: ['./cart-list-dialog.component.scss']
})
export class CartListDialogComponent implements OnInit {
  @ViewChildren('formElm') formElms;
  loading = false;
  user: string; // 目前使用者(後台)的中文名稱

  callerInfo: any;
  callerCartList = [];

  totalPrice = 0;
  totalPriceWithoutCard = 0;

  // Member
  memberInfo: any = '';

  // Form
  myForm: FormGroup;
  userInfo: any;
  pay_delivery$: Subscription;
  pay_type$: Subscription;
  isSame$: Subscription;
  isClickConfirmBtn = false;

  // Page
  currentPage = '1';

  // Coupon
  fullAmountCoupon = '';
  memberCoupon = [];

  //
  finalCartPrice = 0;
  isDiscount = false;
  myEmoney = 0;
  myfinalEmoney = 0;

  isFromBackend = false;

  constructor(
    private mytoastr: MytoastrService,
    private userService: UserService,
    private api: ApiService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CartListDialogComponent>,
    private apiService: ApiService,
    private imgService: ImgService,
  ) {
    console.log(this.data);
    if (this.data.isFromBackend) { this.isFromBackend = true; }
    this.callerInfo = this.data.callerInfo;
    this.callerCartList = this.data.callerCartList;
    this.callerCartList.forEach((e, idx) => {
      this.totalPrice += e.tmod09 * e.tmod11;
      this.getImage('callerCartList', idx, e.tmod06);

      if (e.tmod13 !== '電話卡') {
        this.totalPriceWithoutCard += e.tmod09 * e.tmod11;
      }

    });
    this.finalCartPrice = this.totalPrice;
    // this.getFreeCoupon();

    if (this.isFromBackend) {
      this.memberInfo = this.data.memberInfo;
      console.log('========== From register page ==========');
      console.log(this.memberInfo);
      this.myForm  = fb.group({
        'dmo05': [this.memberInfo.mma03, Validators.required],
        'dmo06': [this.memberInfo.mma04, Validators.required],
        'dmo07': [this.memberInfo.mma05],
        'dmo08': [this.memberInfo.mma06, Validators.maxLength(30)],
        'dmo09': [this.memberInfo.mma10, Validators.maxLength(10)],
        'dmo10': ['貨運', [Validators.required, Validators.maxLength(4)]],
        'dmo11': [''],
        'dmo12': [''],
        'dmo13': [''],
        'dmo14': [''],
        'dmo15': ['', Validators.maxLength(30)],
        'dmo16': [''],
        'dmo17': [0, Validators.maxLength(10)],
        'payType': ['貨到付款', Validators.required],
        'isSame': [false],
        'receiver_name': ['', [Validators.required, Validators.maxLength(30)]],
        'receiver_phone': ['', [Validators.required, Validators.maxLength(11)]],
      });
      this.setInit();
      this.currentPage = '2';
    } else {
      if (this.callerInfo.role === 'member') {
        if (this.callerInfo.member) {
          this.memberInfo = this.callerInfo.member;
          console.log('========== From register page ==========');
          console.log(this.memberInfo);
          this.myForm  = fb.group({
            'dmo05': [this.memberInfo.mma03, Validators.required],
            'dmo06': [this.memberInfo.mma04, Validators.required],
            'dmo07': [this.memberInfo.mma05],
            'dmo08': [this.memberInfo.mma06, Validators.maxLength(30)],
            'dmo09': [this.memberInfo.mma10, Validators.maxLength(10)],
            'dmo10': ['貨運', [Validators.required, Validators.maxLength(4)]],
            'dmo11': [''],
            'dmo12': [''],
            'dmo13': [''],
            'dmo14': [''],
            'dmo15': ['', Validators.maxLength(30)],
            'dmo16': [''],
            'dmo17': [0, Validators.maxLength(10)],
            'payType': ['貨到付款', Validators.required],
            'isSame': [false],
            'receiver_name': ['', [Validators.required, Validators.maxLength(30)]],
            'receiver_phone': ['', [Validators.required, Validators.maxLength(11)]],
          });
          this.setInit();
        } else {
          this.api.post('smma', { mma03:  this.callerInfo.uid}).subscribe((res: Response) => {
            if (res.success) {
              this.memberInfo = res.payload;
              console.log('========== Member Directly ==========');
              console.log(this.memberInfo);
              this.myForm  = fb.group({
                'dmo05': [this.memberInfo.mma03, Validators.required],
                'dmo06': [this.memberInfo.mma04, Validators.required],
                'dmo07': [this.memberInfo.mma05],
                'dmo08': [this.memberInfo.mma06, Validators.maxLength(30)],
                'dmo09': [this.memberInfo.mma10, Validators.maxLength(10)],
                'dmo10': ['貨運', [Validators.required, Validators.maxLength(4)]],
                'dmo11': [''],
                'dmo12': [''],
                'dmo13': [''],
                'dmo14': [''],
                'dmo15': ['', Validators.maxLength(30)],
                'dmo16': [''],
                'dmo17': [0, Validators.maxLength(10)],
                'payType': ['貨到付款', Validators.required],
                'isSame': [false],
                'receiver_name': ['', [Validators.required, Validators.maxLength(30)]],
                'receiver_phone': ['', [Validators.required, Validators.maxLength(11)]],
              });
              this.setInit();
            }
          });
        }
      }
    }
  }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(res => {
      if (res) {
        console.log(res);
        this.user = res.userInfo.smi04;
      }
    });
  }

  setInit() {
    this.isSame$ = this.myForm.get('isSame').valueChanges.subscribe(val => {
      console.log(val);
      if (this.memberInfo) {
        if (val) {
          this.myForm.get('receiver_name').setValue(this.memberInfo.mma06);
          this.myForm.get('receiver_phone').setValue(this.memberInfo.mma26);
          if (this.memberInfo.mma22) {
            this.myForm.get('dmo16').setValue(this.memberInfo.mma22);
          }
        } else {
          this.myForm.get('receiver_name').setValue('');
          this.myForm.get('receiver_phone').setValue('');
          this.myForm.get('dmo16').setValue('');
        }
      }
    });
    this.myEmoney = this.memberInfo.mma27;
    this.myfinalEmoney = this.myEmoney;
    if (this.totalPriceWithoutCard >= 1500 && this.myEmoney >= 100) {
      this.isDiscount = true;
    } else {
      this.isDiscount = false;
    }
    this.getFinalAmount();
    // this.getMemberCoupon();
  }

  /***** Button ******/
  closeDialog(data = '') {
    this.dialogRef.close(data);
  }

  /***** Function *****/
  getImage(listName, index, pid) {
    this.apiService.aget('product/img/' + pid).subscribe((res: any) => {
      if (res.success) {
        if (res.payload) {
          this[listName][index]['img'] = 'data:image/jpeg;base64,' + this.imgService.base64ArrayBuffer(res.payload.dma27.data);
        }
      }
    });
  }
  getFinalAmount() {
    this.myEmoney = this.memberInfo.mma27;
    this.myfinalEmoney = this.myEmoney;
    if (this.isDiscount) {
      this.finalCartPrice -= 100;
      this.myfinalEmoney -= 100;
    } else {
      // this.finalCartPrice += 100;
      // this.myfinalEmoney += 100;
    }
  }
  /*** 取得可獲得的優惠券 ***/
  getFreeCoupon() {
    this.apiService.post('ssmu/ssmu02', {totalPrice: this.totalPrice}).subscribe((res: Response) => {
      console.log(res);
      if (res.success) {
        this.fullAmountCoupon = res.payload;
      }
    });
  }
  /*** 取得目前Member可用的優惠券 ***/
  getMemberCoupon() {
    const memberId = this.memberInfo.mma04; // 會員編號
    const lang = this.callerInfo.language; // 打電話過來時的語言
    this.apiService.post('ssmu/member/all', {memberId, lang}).subscribe((res: Response) => {
      console.log(res);
      if (res.success) {
        // const tmp = res.payload;
        // tmp.forEach(e => {
        //   e['useCouponAmount'] = 0;
        //   e['isUseCoupon'] = false;
        // });
        this.memberCoupon = res.payload;
      }
    });
  }
  createOrder(order_value) {
    this.isClickConfirmBtn = true;
    if (!this.myForm.valid) {
      const firstErrorInputElm = this.formElms['_results'].find(e => {
        return e.nativeElement.classList.value.includes('ng-invalid');
      });
      return setTimeout(() => {
        firstErrorInputElm.nativeElement.focus();
      }, 300);
    }

    const cartList = [];
    this.callerCartList.forEach(e => {

      cartList.push({
        pid: e.tmod06,
        amount: e.tmod11,
        info: {
          pname: e.tmod08,
          pprice: e.tmod09,
          premark: e.tmod13
        }
      });
    });

    Object.assign(order_value, {
      // One Signal ID
      osid: this.data.callerInfo.osid ? this.data.callerInfo.osid : this.data.memberInfo.mma102,
      // PDMO
      dmo17: this.finalCartPrice,
      // SYS
      user: this.user,
      // PDMOD
      cartList,
      // SMMA
      lang: 'id',
      isDiscount: this.isDiscount,
      myfinalEmoney: this.myfinalEmoney
    });

    console.log(order_value);
    this.loading = true;
    this.api.fpost('order/insert', order_value).subscribe((res: any) => {
      this.loading = false;
      console.log(res);
      if (res.success) {
        this.mytoastr.success(35);
        this.clearCart();
        this.currentPage = '3';
      } else {
        this.mytoastr.error(35);
        console.log(res.payload);
      }
    }, err => {
      this.loading = false;
      alert('網路錯誤');
      console.log(err);
    });
  }
  clearCart() {
    this.api.apost('tpdmod/clearCart', { userInfo: this.memberInfo }).subscribe((res) => {
      console.log(res);
      this.callerCartList = [];
    });
  }
}
