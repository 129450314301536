import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ReplaySubject, Observable } from '../../../node_modules/rxjs';
import { take } from '../../../node_modules/rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  language$ = new ReplaySubject<LangChangeEvent>(1);
  flag$ = new ReplaySubject<string>(1);
  translate = this.translateService;

  // 國旗對照
  countryMap = new Map()
    .set('en', 'flag-icon-us')
    .set('zh-tw', 'flag-icon-tw')
    .set('ph', 'flag-icon-ph')
    .set('vn', 'flag-icon-vn')
    .set('id', 'flag-icon-id')
    .set('th', 'flag-icon-th');
    // .set('mm', 'flag-icon-mm')

  constructor(public translateService: TranslateService) { }

  setInitState() {
    this.translateService.addLangs(['zh-tw', 'ph', 'vn', 'id', 'th']);
    // 根據使用者的瀏覽器語言設定，如果是中文就顯示中文，否則都顯示英文
    // 繁體/簡體中文代碼都是zh
    const browserLang = (this.translate.getBrowserLang().includes('zh')) ? 'zh-tw' : 'ph'  ;
    this.setLang(browserLang);
  }

  setLang(lang: string) {
    this.translateService.onLangChange.pipe(take(1)).subscribe(result => {
      this.language$.next(result);
      this.flag$.next(this.countryMap.get(result.lang));
      // console.log(this.flag$);
      // console.log(this.translateService);
      // console.log(this.language$);
      // console.log(result);
    });
    this.translateService.use(lang);
  }

  getCurrentLanguage(): Observable<any> {
    return this.language$;
  }

  getCurrentFlag(): Observable<string> {
    return this.flag$;
  }

}
