import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfig } from './../app.config';
import { UtilsService } from './utils.service';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private dateService: DateService,
    private utilsService: UtilsService,
    private http: HttpClient
  ) { }

  getList(endpoint: string, value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/tables/list', value);
    // return this.http.post(appConfig.apiUrl + '/api/tables/' + endpoint + '/list', value);
  }

  getList2(endpoint: string, value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/tables/list2', value);
    // return this.http.post(appConfig.apiUrl + '/api/tables/' + endpoint + '/list', value);
  }

  getAll(endpoint: string) {
    return this.http.get(appConfig.apiUrl + '/api/' + endpoint);
  }

  aget(endpoint: string) {
    return this.http.get(appConfig.apiUrl + '/api/app/' + endpoint);
  }

  add(endpoint: string, value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/tables/' + endpoint + '/insert', value);
  }

  revise(endpoint: string, value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/tables/' + endpoint + '/update', value);
  }

  delete(endpoint: string, value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/tables/delete', value);
    // return this.http.post(appConfig.apiUrl + '/api/tables/' + endpoint + '/delete', value);
  }

  delete2(endpoint: string, value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/tables/delete2', value);
    // return this.http.post(appConfig.apiUrl + '/api/tables/' + endpoint + '/delete', value);
  }

  getImgs(endpoint: string, value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/tables/' + endpoint + '/imgs', value);
  }

  post(endpoint: string, value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    value['today'] = this.dateService.storeDateToDB(new Date(), 0);
    value['time'] = this.dateService.storeDateToDB(new Date(), 1);
    return this.http.post(appConfig.apiUrl + '/api/tables/' + endpoint, value);
  }

  fpost(endpoint: string, value: any) {
    const token = this.utilsService.getToken('', 'frontend');
    value['token'] = token;
    value['today'] = this.dateService.YYYYMMDD(new Date());
    return this.http.post(appConfig.apiUrl + '/api/front/' + endpoint, value);
  }

  apost(endpoint: string, value: any) {
    return this.http.post(appConfig.apiUrl + '/api/app/' + endpoint, value);
  }

  postAll(endpoint: string, value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/' + endpoint, value);
  }


  postBlob(endpoint: string, value: any) {
    const token = this.utilsService.getToken();
    value.obj['token'] = token;
    value.obj['today'] = this.dateService.YYYYMMDD(new Date());
    value.obj['time'] = this.dateService.YYYYMMDDTHHMMSS(new Date());
    const reqObj = jsonToFormdataType(value.obj);
    const body = new FormData();
    Object.keys(reqObj).forEach(key => body.append(key, reqObj[key])); // json data
    if (Object.keys(value.blob).length !== 0) { Object.keys(value.blob).forEach(key => body.append(key, value.blob[key])); } // file data
    return this.http.post(appConfig.apiUrl + '/api/tables/' + endpoint, body);
  }

  fpostBlob(endpoint: string, value: any, isLogin: boolean) {
    if (isLogin) { value.obj['token'] = this.utilsService.getToken('', 'frontend'); }
    value.obj['today'] = this.dateService.YYYYMMDD(new Date());
    const reqObj = jsonToFormdataType(value.obj);
    const body = new FormData();
    Object.keys(reqObj).forEach(key => body.append(key, reqObj[key])); // json data
    if (Object.keys(value.blob).length !== 0) { Object.keys(value.blob).forEach(key => body.append(key, value.blob[key])); } // file data
    return this.http.post(appConfig.apiUrl + '/api/' + endpoint, body);
  }

  getEmployee(value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/employee/', value);
  }

  getVendor(value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/vendor/', value);
  }

  getPhone(value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/telecome/phone', value);
  }

  searchPhone(value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/telecome/phone', value);
  }

  getPermission(value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/users/permission/search', value);
  }

  addPermission(value: any) {
    const token = this.utilsService.getToken();
    value['token'] = token;
    return this.http.post(appConfig.apiUrl + '/api/users/permission/add', value);
  }

  // Front
  getProductList(lang, sortNo, level) {
    return this.http.post(appConfig.apiUrl + '/api/front/product/type/list', { lang, sortNo, level });
  }

}

function jsonToFormdataType(json) {
  const res = {};
  Object.keys(json).forEach(key => {
    if (json[key] === null) {
      res[key] = null;
    } else {
      if (typeof json[key] === 'object') {
        const tmpJson = jsonToFormdataType(json[key]);
        Object.keys(tmpJson).forEach(k => {
          const tmpKey = key + '.' + k;
          res[tmpKey] = tmpJson[k];
        });
      } else {
        res[key] = json[key];
      }
    }
  });
  return res;
}
