<button type="btn" class="btn btn-secondary btn-sm" style="float: right;" (click)="closeDialog()"><i class="fas fa-times"></i></button>
<div class="cus-row">
  <div class="col-4">
    <h5 class="text-center">篩選條件</h5>
    <div class="form-inline cus-mb-input">
      <div class="col-12 p-0 form-group">
        <label class="label-input">階層一</label>
        <div class="col p-0">
          <select #formElm class="form-control form-control-sm w-100"
            [(ngModel)]="level1SelectedValue"
            (change)="selectedSortValueOnChange(1)">
            <option value="">選擇類別</option>
            <option *ngIf="!level1List.length" value="none" disabled>無</option>
            <option *ngFor="let sort of level1List;" [value]="sort.dmk03">{{ sort.dmk04 }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="form-inline cus-mb-input">
      <div class="col-12 p-0 form-group">
        <label class="label-input">階層二</label>
        <div class="col p-0">
          <select #formElm class="form-control form-control-sm w-100"
            [(ngModel)]="level2SelectedValue"
            (change)="selectedSortValueOnChange(2)">
            <option value="">選擇類別</option>
            <option *ngIf="!level2List.length" value="none" disabled>無</option>
            <option *ngFor="let sort of level2List;" [value]="sort.dmk03">{{ sort.dmk04 }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="form-inline cus-mb-input">
      <div class="col-12 p-0 form-group">
        <label class="label-input">階層三</label>
        <div class="col p-0">
          <select #formElm class="form-control form-control-sm w-100"
            [(ngModel)]="level3SelectedValue"
            (change)="selectedSortValueOnChange(3)">
            <option value="">選擇類別</option>
            <option *ngIf="!level3List.length" value="none" disabled>無</option>
            <option *ngFor="let sort of level3List;" [value]="sort.dmk03">{{ sort.dmk04 }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <h5 class="text-center">選擇商品</h5>
    <div class="productList-container">
      <button type="button" class="list-group-item list-group-item-action" *ngFor="let product of productList; let idx = index;"
        [class.selected]="selected === idx" (click)="selected = idx; selectedProduct = product;">
        {{ product.dma04 }}</button>
      <h5 *ngIf="!isProduct" class="text-center mt-3">無此分類商品</h5>
    </div>
  </div>
  <div class="col-2">
    <h5 class="text-center"> </h5>
    <button type="button" class="btn" [class.btn-primary]="selectedProduct" [class.btn-secondary]="!selectedProduct" [disabled]="!selectedProduct" (click)="closeDialog(selectedProduct)">確定</button>
  </div>
</div>
