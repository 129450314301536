import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreloadAllModules } from '@angular/router';

// Constants
import { appPath } from './app-path.const';

const routes: Routes = [
  {
    path: '',
    loadChildren: './pages/front/layout/layout.module#LayoutModule'
    // loadChildren: './pages/login/login.module#LoginModule'
  },
  {
    path: appPath.login,
    loadChildren: './pages/login/login.module#LoginModule'
  },
  {
    path: appPath.homePage,
    loadChildren: './pages/services-menu/services-menu.module#ServicesMenuModule'
  },
  {
    path: appPath.module_system,
    loadChildren: './pages/module-system/layout/layout.module#LayoutModule'
  },
  {
    path: appPath.module_employee,
    loadChildren: './pages/module-employee/layout/layout.module#LayoutModule'
  },
  {
    path: appPath.module_member,
    loadChildren: './pages/module-member/layout/layout.module#LayoutModule'
  },
  {
    path: appPath.module_product,
    loadChildren: './pages/module-product/layout/layout.module#LayoutModule'
  },


  // {
  //   path: appPath.module_role,
  //   loadChildren: './pages/module-role/layout/layout.module#LayoutModule'
  // },
  // {
  //   path: appPath.module_telecome,
  //   loadChildren: './pages/module-telecome/layout/layout.module#LayoutModule'
  // },
  // {
  //   path: appPath.module_remittance,
  //   loadChildren: './pages/module-remittance/layout/layout.module#LayoutModule'
  // },
  {
    path: '**',
    redirectTo: appPath.front.home,
    // redirectTo: appPath.login,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    preloadingStrategy: PreloadAllModules,
    // enableTracing: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
