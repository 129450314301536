import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export interface DialogMessage {
  text?: string;
}

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit {

  type = '';
  title = '';
  messageArray: DialogMessage[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CommonDialogComponent>,
  ) {
    this.title = this.data.title;
    this.messageArray = this.data.messageArray;
  }

  ngOnInit() {
  }

  closeDialog(isDelete) {
    this.dialogRef.close(isDelete);
  }

}

