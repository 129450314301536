import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  YYMM(rawDate) {
    const tempDate = new Date(rawDate);
    const year = tempDate.getFullYear().toString().slice(2);
    let month = (1 + tempDate.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    return year + month;
  }

  YYYYMMDD(rawDate) {
    const tempDate = new Date(rawDate);
    const year = tempDate.getFullYear();
    let month = (1 + tempDate.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    let day = tempDate.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return year + '-' + month + '-' + day;
  }

  YYYYMMDDTHHMMSS(rawDate) {
    const tempDate = new Date(rawDate);
    const year = tempDate.getFullYear();
    let month = (1 + tempDate.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    let day = tempDate.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    let hour = tempDate.getHours().toString();
    hour = hour.length > 1 ? hour : '0' + hour;
    let minute = tempDate.getMinutes().toString();
    minute = minute.length > 1 ? minute : '0' + minute;
    let second = tempDate.getSeconds().toString();
    second = second.length > 1 ? second : '0' + second;
    return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second;
  }

  storeDateToDB(rawDate, type) {
    let date = '';
    if (type === 0) { // date
      date = this.YYYYMMDD(rawDate);
    } else if (type === 1) { // datetime
      date = this.YYYYMMDDTHHMMSS(rawDate);
    }
    // console.log(date);
    // console.log(new Date(date).toUTCString());
    return new Date(date).toUTCString();
  }

  isDateExit(rawDate, timeType) {
    const tempDate = new Date(rawDate);
    const year = tempDate.getFullYear();
    if (year === 1911) { return ''; } // 1991 為預設值，表示無日期

    if (timeType === 'date') {
      return this.YYYYMMDD(rawDate);
    } else { // dateTime
      return this.YYYYMMDDTHHMMSS(rawDate);
    }
  }

}
