<button type="btn" class="btn btn-secondary btn-sm" style="float: right;" (click)="closeDialog()"><i class="fas fa-times"></i></button>
<h4 class="text-center mb-3">批次產品更新</h4>
<div class="form-inline cus-mb-input">
  <div class="col-3 p-0 form-group">
    <a class="btn btn-link p-0" target="_self" href="assets/product.xlsx" download="產品批次更新範例.xlsx">範例下載</a>
  </div>
  <div class="col-9 p-0 form-group">
    <div class="col p-0">
      <input #fileElm type="file" accept=".csv,.xls,.xlsx" class="w-100" placeholder=""
        style="display: none"
        (change)="incomingfile($event)">
      <input #uploadElm type="button" value="選擇檔案" class="mr-1"
        (click)="uploadFileBtn()">{{ uploadFileName }}
    </div>
  </div>
</div>

<div *ngIf="uploadStatus === 1">正在上傳</div>
<div *ngIf="uploadStatus === 2">上傳完成</div>
<div class="progress mb-2" *ngIf="validList.length">
  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
    aria-valuemin="0" aria-valuemax="100"
    [attr.aria-valuenow]="currentProgres" [style.width]="currentProgres+'%'">{{ currentProgres }}%</div>
</div>

<section *ngIf="validList.length">
  <div class="alert alert-success" role="alert">
    檔案選取成功，共 {{ validList.length }} 筆資料
    <button *ngIf="uploadStatus === 0 && validList.length" class="btn btn-primary" style="position: absolute;top: 50%;transform: translateY(-50%);right: 10px;" (click)="upload()">開始上傳</button>
  </div>
</section>

<!-- <section *ngIf="invalidList.length">
  <div class="alert alert-danger" role="alert">
    錯誤資料格式，共 {{ invalidList.length }} 筆資料
    <button type="button" class="btn btn-link" (click)="openBatchUploadDialog('選取失敗清單', invalidList)">清單</button>
    <div style="color: red;">缺少必填欄位"品號"、"品名"、"定價"、"庫存"</div>
  </div>
</section>

<section *ngIf="uploadStatus === 2">
  <div class="alert alert-success" role="alert">
    資料上傳成功，共 {{ newList.length + repeatList.length }} 筆資料
  </div>
  <div class="alert alert-info" role="alert">
    新增商品，共 {{ newList.length }} 筆資料
  </div>
  <div *ngIf="repeatList.length" class="alert alert-danger" role="alert">
    更新商品，共 {{ repeatList.length }} 筆資料
    <button type="button" class="btn btn-link" (click)="exportToExcel(repeatList, '商品更新清單')">清單</button>
  </div>
</section> -->

<!-- <section *ngIf="repeatList.length">
  <div class="alert alert-danger" role="alert">
    資料上傳失敗，共 {{ duplicaedList.length }} 筆資料
    <button type="button" class="btn btn-link" (click)="openBatchUploadDialog('上傳失敗清單', duplicaedList)">清單</button>
    <div style="color: red;">品號重複</div>
  </div>
</section> -->

<button *ngIf="uploadStatus === 2" class="btn btn-primary" style="display: block; margin: 0 auto;" (click)="closeDialog(true)">確定</button>

<ngx-loading [show]="loading"></ngx-loading>
