<mat-toolbar class="navbar-height bigking-bg-blue">
  <button mat-button (click)="goFrontend()" [disabled]="(buttonMode$ | async) !== 0" >
    <h1 style="color: white;">
      <span class="flag-icon flag-icon-logo"></span>
      &nbsp;玉凰國際貿易有限公司
    </h1>
  </button>
  <div class="to-left ml-5" style="color: white;">
    <h3 *ngIf="login">{{ 'menu.version' | translate: {'num': '1.3.8'} }}</h3>
  </div>
  <button mat-button class="cus-tooltip" style="font-size: 1.2rem; color: #ffffff;" (click)="goFrontend()" [disabled]="(buttonMode$ | async) !== 0">
    <i class="fas fa-home"></i>
    <span class="tooltiptext-bottom">前台首頁</span>
  </button>
  <button mat-button *ngIf="login" class="cus-tooltip" style="color: white;" (click)="goHomePage()" [disabled]="(buttonMode$ | async) !== 0">
    {{ 'menu.module_menu' | translate }}
    <span class="tooltiptext-bottom">後台主選單</span>
  </button>
  <!-- 訂單 -->
  <button mat-button [matMenuTriggerFor]="orderMenu" class="cus-tooltip" style="font-size: 1.2rem; color: #ffffff;"
    *ngIf="login"
    [disabled]="(buttonMode$ | async) !== 0">
    <i class="fas fa-clipboard-list"></i>
    <span *ngIf="unreadOrderTotal" class="badge badge-light" style="color: #ffffff; background: red;">{{ unreadOrderTotal }}</span>
    <span class="tooltiptext-bottom">最新訂單</span>
  </button>
  <button mat-button [matMenuTriggerFor]="messageMenu" class="cus-tooltip" style="font-size: 1.2rem; color: #ffffff;"
    *ngIf="login && isCSPermission"
    [disabled]="(buttonMode$ | async) !== 0">
    <i class="fas fa-comment-dots"></i>
    <span *ngIf="unreadMessageTotal" class="badge badge-light" style="color: #ffffff; background: red;">{{ unreadMessageTotal }}</span>
    <span class="tooltiptext-bottom">會員留言</span>
  </button>
  <button mat-button [matMenuTriggerFor]="flagMenu" class="cus-tooltip" style="color: white;">
    <span class="tooltiptext-bottom">切換語言</span>
    <span class="flag-icon" [ngClass]="flag$ | async"></span> <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <button mat-button [matMenuTriggerFor]="memeberMenu" *ngIf="login" style="color: white;">
    <span>{{ currentUser?.smi04 }}</span> <mat-icon>arrow_drop_down</mat-icon>
  </button>
</mat-toolbar>

<!-- 客服連線 -->
<ng-container *ngIf="isCSPermission && login && !callerInfo">
  <div class="switch-cs">
    <div *ngIf="!changingStatusFromServer" class="text-center">
      {{ isCSOnline ? '客服已連線' : '客服未連線' }}
    </div>
    <div class="comming-call-icon">
      <img [src]="isCSOnline ? 'assets/img/phone/csr.png' : 'assets/img/phone/csr-offline.png'" alt="" >
    </div>
    <p *ngIf="changingStatusFromServer && isCSOnline" class="answering" style="margin: 5px 0px;">正在連線</p>
    <p *ngIf="changingStatusFromServer && !isCSOnline" class="answering" style="margin: 5px 0px;">正在離線</p>
    <label *ngIf="!changingStatusFromServer" class="switch">
      <input type="checkbox" [(ngModel)]="isCSOnline" (change)="switchCS()">
      <span class="slider round"></span>
    </label>
  </div>
</ng-container>

<!-- 客服來電 -->
<div class="comming-call-dialog" *ngIf="callerInfo && !b_isAcceptedCall">
  <div class="comming-call-icon">
    <img src="assets/img/phone/csr.png" alt="" >
  </div>
  <div class="comming-call-body">
    <div class="cus-row">
      <p class="b1">姓名:</p>
      <p class="b2">{{ callerInfo.uname }}</p>
      <p class="b1">國籍:</p>
      <p class="b2">{{ callerInfo.language }}</p>
      <p class="b1">來源:</p>
      <p class="b2">{{ callerInfo.source }}</p>
    </div>
  </div>
  <div class="comming-call-bottom cus-row">
    <div class="col-6">
      <img class="pickup" src="assets/img/phone/pickup.png" alt="" (click)="answer()">
    </div>
    <div class="col-6">
      <div (click)="hangup()">
        <img src="assets/img/phone/handup.png" alt="">
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="callerInfo && b_isAcceptedCall && isCSPermission">
  <div id="mySidenav" class="sidenav">
    <div id="about"><img src="assets/img/phone/user.png" alt="">來電者小簡介
      <div id="callerInfo">
        <div class="text-center p-0">來電者</div>
        <div class="cus-row">
          <div class="col-12 p-0">姓名：{{ callerInfo.uname }}</div>
          <div class="col-12 p-0">國籍：{{ callerInfo.language }}</div>
          <div class="col-12 p-0">來源：{{ callerInfo.source }}</div>
        </div>
      </div>
    </div>
    <div id="blog" (click)="openCartListDialog()">
      <img src="assets/img/phone/cart.png" alt="">查看訂單</div>
    <div id="memberInfo" (click)="goMemberPage()" *ngIf="callerInfo.role === 'member'">
      <img src="assets/img/phone/memberInfo.png" alt="">會員資料</div>
    <div id="projects" (click)="goRegisterPage(callerInfo.uid)" *ngIf="callerInfo.role === 'guest'">
      <img src="assets/img/phone/addMember.png" alt="">註冊會員</div>
    <div id="cancel" (click)="hangup()"><img src="assets/img/phone/handup-2.png" alt="">結束通話</div>
  </div>
  <div class="comming-call-dialog-2">
    <div class="comming-call-icon">
      <img src="assets/img/phone/csr.png" alt="" >
    </div>
    <p class="answering" style="margin: 5px 0px;">通話中</p>
  </div>
</ng-container>

<!-- Testing -->
<!-- <div id="mySidenav" class="sidenav">
  <div id="about"><img src="assets/img/phone/user.png" alt="">來電者小簡介
    <div id="callerInfo">
      <div class="text-center p-0">來電者</div>
      <div class="cus-row">
        <div class="col-12 p-0">姓名：12345678</div>
        <div class="col-12 p-0">國籍：台灣</div>
        <div class="col-12 p-0">來源：註冊頁面</div>
      </div>
    </div>
  </div>
  <div id="blog" (click)="openCartListDialog()">查看訂單</div>
  <div id="memberInfo">會員資料</div>
  <div id="projects" (click)="goRegisterPage()">註冊會員</div>
  <div id="cancel" (click)="hangup()"><img src="assets/img/phone/handup-2.png" alt="">結束通話</div>
</div>
<div class="comming-call-dialog-2">
  <div class="comming-call-icon">
    <img src="assets/img/phone/csr.png" alt="" >
  </div>
  <p class="answering" style="margin: 5px 0px;">通話中</p>
</div> -->


<mat-menu x-position="before" #flagMenu="matMenu">
  <a *ngFor="let lang of languageList;" style="color:black;" mat-menu-item (click)="changeLanguage(lang)" [hidden]="lang === (language$ | async).lang">
    <span class="flag-icon mr-1" [ngClass]="getFlag(lang)"></span>{{ 'menu.languageList.' + lang | translate }}
  </a>
</mat-menu>

<mat-menu x-position="before" #memeberMenu="matMenu">
  <button mat-menu-item style="color:black; text-align: center;" (click)="logout()" [disabled]="(buttonMode$ | async) !== 0">{{ 'menu.logout' | translate }}</button>
</mat-menu>

<mat-menu x-position="before" #messageMenu="matMenu">
  <a *ngFor="let unread of unreadMessageList;" class="unreadMessageBlock" mat-menu-item (click)="goSSMTPage(unread.smt04)">
    <p class="name">{{ unread.smt05 }}</p>
    <p class="message">{{ unread.smt09 }}</p>
    <p class="time">{{ unread.dmo03 }}</p>
  </a>
</mat-menu>

<mat-menu x-position="before" #orderMenu="matMenu">
  <a *ngFor="let unread of unreadOrderList;" class="unreadMessageBlock" mat-menu-item (click)="goPDMOPage(unread.dmo04)">
    <p class="name">交易日期：{{ unread.dmo03 }}</p>
    <p>交易單號：{{ unread.dmo04 }}</p>
    <p>訂購人：{{ unread.dmo08 }}</p>
    <!-- <p>{{ unread.dmo09 }}</p> -->
  </a>
</mat-menu>

