import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from './../../../../services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FoucsService } from '../../../../services/foucs.service';

@Component({
  selector: 'app-choose-sort-dialog',
  templateUrl: './choose-sort-dialog.component.html',
  styleUrls: ['./choose-sort-dialog.component.scss']
})
export class ChooseSortDialogComponent implements OnInit {
  @ViewChild('formElms') formElms: ElementRef;

  // Form
  myForm: FormGroup;
  isSaveBtnClicked = false;

  productTypeList = []; // all type
  level1TypeList = []; // level1 type
  level2TypeList = []; // level2 type
  level3TypeList = []; // level3 type
  selectedLevel1Type = '';
  selectedLevel2Type = '';
  selectedLevel3Type = '';

  isInit = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ChooseSortDialogComponent>,
    private apiService: ApiService,
    private fb: FormBuilder,
    private focusService: FoucsService,
  ) {
    this.myForm  = fb.group({
      'dma09': ['', Validators.required],
      'dma10': ['', Validators.required],
      'dma47': ['', Validators.required],
      'dma48': ['', Validators.required],
      'dma49': ['', Validators.required],
      'dma50': ['', Validators.required],
    });
  }

  ngOnInit() {
    this.apiService.getList('pdmk', {table: 'pdmk'}).subscribe((res: any) => {
      if (res) {
        console.log(res);
        const typeList = res.data;
        if (typeList.length) {
          this.productTypeList = typeList;
          this.level1TypeList = typeList.filter(e => e.dmk11 === '01');

          console.log(this.data);
          if (!this.isInit) { this.selectedLevel1Type = this.data.dma10; }
        }
      }
    });
  }

  onDMA10Changes(): void {
    this.selectedLevel2Type = '';
    this.selectedLevel3Type = '';
    if (this.selectedLevel1Type) {
      const type = this.level1TypeList.find(t => t.dmk04 === this.selectedLevel1Type);
      this.level2TypeList = this.productTypeList.filter(t => t.dmk10 === type.dmk03);
      this.level3TypeList = [];
      this.myForm.controls['dma09'].setValue(type.dmk03);

      if (!this.isInit) { this.selectedLevel2Type = this.data.dma48; }
    }
  }

  onDMA48Changes(): void {
    this.selectedLevel3Type = '';
    if (this.selectedLevel2Type) {
      const type = this.level2TypeList.find(t => t.dmk04 === this.selectedLevel2Type);
      this.level3TypeList = this.productTypeList.filter(t => t.dmk10 === type.dmk03);
      this.myForm.controls['dma47'].setValue(type.dmk03);

      if (!this.isInit) { this.selectedLevel3Type = this.data.dma50; this.isInit = true; }
    } else {
      this.myForm.controls['dma47'].setValue('');
    }
  }

  onDMA50Changes(): void {
    if (this.selectedLevel3Type) {
      const type = this.level3TypeList.find(t => t.dmk04 === this.selectedLevel3Type);
      this.myForm.controls['dma49'].setValue(type.dmk03);
    } else {
      this.myForm.controls['dma49'].setValue('');
    }
  }

  save(value = '') {
    this.isSaveBtnClicked = true;
    if (!this.myForm.valid) {
      const firstErrorInputElm = this.formElms['_results'].find(e => {
        return e.nativeElement.classList.value.includes('ng-invalid');
      });
      return this.focusService.elmFocus(firstErrorInputElm, 300);
    }
    console.log(value);
    this.dialogRef.close(value);
  }

}
