<mat-toolbar class="navbar-front-height">
  <!-- <button id="f-menu-icon" mat-icon-button (click)="onToggleSidenav()">
    <mat-icon>menu</mat-icon>
  </button> -->
  <!-- <img src="assets/img/bigking-shopping.png" alt="" class="f-menu-logo" (click)="goFrontend()"> -->


  <!-- <img [src]="f_site_settings.siteLogo ? 'data:image/jpeg;base64,' + imgService.base64ArrayBuffer(f_site_settings.siteLogo.data) : ''" alt="" class="f-menu-logo" (click)="goFrontend()"> -->
  <img src="assets/img/logo.jpg" alt="" class="f-menu-logo" (click)="goFrontend()">
  <button mat-button class="py-0" (click)="goFrontend()">
    <!-- <p class="f-menu-title m-0">{{ f_site_settings.siteNameText }}</p> -->
    <p class="f-menu-title m-0">JPK Family</p>
  </button>
  <!-- <img src="assets/img/home/text-img.png" class="f-menu-logo" (click)="goFrontend()" alt="" style="cursor: pointer;"> -->
  <div class="to-left ml-5">

    <div id="search-container" class="text-center">
      <form class="cus-row">
        <div class="serach-box-container col p-0">
          <input type="text" placeholder="{{'請輸入關鍵字' | translate}}" (input)="searchProduct($event)" (focus)="isSearchBoxFocus = true;" (blur)="isSearchBoxFocus = false;">
          <div class="search-result-container" *ngIf="isSearchBoxFocus && searchValue">
            <div *ngIf="!isSeraching && !searchResultList.length">0 {{'搜尋結果' | translate}}</div>
            <button type="button"  *ngFor="let item of searchResultList" 
              class="list-group-item list-group-item-action"
              (mousedown)="goProduct(item);">
              {{ item.pname }}
            </button>
          </div>
        </div>
        <!-- <button type="button" class="btn btn-primary btn-more-condition" (click)="moreCondition(); isOpenMoreCondition = !isOpenMoreCondition">篩選</button> -->
        <button type="submit" class="btn btn-secondary btn-submit">
          <!-- <i class="fas fa-search"></i> -->
          {{'搜尋' | translate}}
        </button>
      </form>

      <!-- <div class="cus-row" *ngIf="isOpenMoreCondition">
        <div class="col-3 p-0">
          <select class="form-control form-control-sm w-100" style="box-shadow: unset;" [(ngModel)]="selectedFilterCountry" (ngModelChange)="onCountryChange()">
            <option value="">選擇國家</option>
            <option *ngFor="let country of countryList;" [value]="country.smxc03">{{ country.smxc04 }}</option>
          </select>
        </div>
        <div class="col-4 p-0">
          <select class="form-control form-control-sm w-100" style="box-shadow: unset;" [(ngModel)]="selectedFilterBrand" (ngModelChange)="onBrandChange()">
            <ng-container *ngIf="!selectedFilterCountry">
              <option value="">選擇品牌</option>
              <option value="" disabled>請先選擇國家</option>
            </ng-container>
            <ng-container *ngIf="selectedFilterCountry">
              <option value="">選擇品牌</option>
              <ng-container *ngIf="!brandFilterList.length">
                <option value="" disabled>無</option>
              </ng-container>
              <ng-container *ngIf="brandFilterList.length">
                <option *ngFor="let brand of brandFilterList;" [value]="brand.smxe02">{{ brand.smxe03 }}</option>
              </ng-container>
            </ng-container>
          </select>
        </div>
      </div> -->
    </div>

    <!-- <p class="hot-search-tag">熱搜排行：
      <a href="#">電話卡</a>
      <a href="#">泡麵</a>
      <a href="#">印尼</a>
      <a href="#">洗面乳</a>
      <a href="#">台灣大哥大</a>
      <a href="#">香菜</a>
      <a href="#">OK</a>
    </p> -->

  </div>
  <div id="f-menu-list">
    <button mat-button class="cus-tooltip" style="font-size: 1.2rem;" (click)="goFrontend()">
      <i class="fas fa-home"></i>
      <span class="tooltiptext-bottom">{{'首頁' | translate}}</span>
    </button>
    <button mat-button class="cus-tooltip" style="font-size: 1.2rem;" (click)="goCart()">
      <i class="fas fa-shopping-cart"></i>
      <span class="badge badge-danger" style="font-size: 10px;">{{ cartAmount$ | async }}</span>
      <span class="tooltiptext-bottom">{{'購物車' | translate}}</span>
    </button>
    <button mat-button *ngIf="!(f_login$ | async)" class="cus-tooltip" style="font-size: 1.2rem;" (click)="goFrontRegister()">
      <span style="font-size: 16px; margin-right: 10px;">{{'訪客' | translate}}</span>
      <i class="fas fa-user-plus"></i>
      <span class="tooltiptext-bottom">{{'註冊' | translate}}</span>
    </button>
    <button mat-button *ngIf="!(f_login$ | async)" class="cus-tooltip" style="font-size: 1.2rem;" (click)="goFrontLogin()">
      <i class="fas fa-sign-in-alt"></i>
      <span class="tooltiptext-bottom">{{'登入' | translate}}</span>
    </button>
    <button mat-button *ngIf="f_user$ | async" class="cus-tooltip" style="font-size: 1.2rem;" (click)="goMemberCenter()">
      <span style="font-size: 16px; margin-right: 10px;">{{ (f_user$ | async).userInfo.mma06 }}</span>
      <i class="fas fa-id-card-alt"></i>
      <span class="tooltiptext-bottom">{{'會員中心' | translate}}</span>
    </button>
    <button mat-button *ngIf="f_user$ | async" class="cus-tooltip" style="font-size: 1.2rem;" (click)="logout()">
      <i class="fas fa-sign-out-alt"></i>
      <span class="tooltiptext-bottom">{{'登出' | translate}}</span>
    </button>
    <!-- <button mat-button style="font-size: 0.8rem;">登入</button> -->
    <!-- <button mat-button style="font-size: 0.8rem;">註冊</button> -->
    <!-- <button mat-button style="font-size: 0.8rem;">會員中心</button> -->
    <button mat-button class="cus-tooltip" [matMenuTriggerFor]="flagMenu">
      <span class="flag-icon" [ngClass]="flag$ | async"></span> <mat-icon>arrow_drop_down</mat-icon>
      <span class="tooltiptext-bottom">{{'切換語言' | translate}}</span>
    </button>
    <!-- <button mat-button class="cus-tooltip" style="font-size: 1.2rem;" (click)="goBackend()">
      <i class="fas fa-store"></i>
      <span class="tooltiptext-left">{{'商家登入' | translate}}</span>
    </button> -->
    <!-- <button mat-button (click)="goBackend()" style="font-size: 0.8rem;">{{ 'menu.backend' | translate }}</button> -->
  </div>
</mat-toolbar>

<mat-menu x-position="before" #flagMenu="matMenu">
  <a *ngFor="let lang of languageList;" style="color:black;" mat-menu-item (click)="changeLanguage(lang)" [hidden]="lang === currentLanguage">
    <span class="flag-icon mr-1" [ngClass]="getFlag(lang)"></span>{{ 'menu.languageList.' + lang | translate }}
  </a>
</mat-menu>
