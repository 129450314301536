import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-select-list-dialog',
  templateUrl: './select-list-dialog.component.html',
  styleUrls: ['./select-list-dialog.component.scss']
})
export class SelectListDialogComponent implements OnInit {

  title = '';
  list = [];
  column = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SelectListDialogComponent>
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.title = this.data.title;
    this.list = this.data.list;
    this.column = this.data.column;
  }

  closeDialog(data) {
    this.dialogRef.close(data);
  }

}
