<h4 mat-dialog-title>
  {{ title }}
</h4>
<div style="padding: 25px 0; font-size: 18px;">
  <p class="m-0" *ngFor="let line of messageArray">{{ line.text }}</p>
</div>
<div class="cus-row">
  <div class="col-6 p-0">
    <button type="button" class="btn btn-outline-secondary btn-block" style="border: 0;" (click)="closeDialog(false)">否</button>
  </div>
  <div class="col-6 p-0">
    <button type="button" class="btn btn-outline-danger btn-block" style="border: 0;" (click)="closeDialog(true)">是</button>
  </div>
</div>
