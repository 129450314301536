import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private userService: UserService,
  ) { }

  /* Button Type
  * 新增: 08
  * 修改: 09
  * 刪除: 10
  * 查詢: 11
  * 列印: 12
  * 輸出: 13
  */

  checkButtonPermisson(table: string, btnType) {
    const permissionList = this.userService.currentUser.userPermission;
    const tablePermission = permissionList.find(t => t.sma05 === table.toUpperCase());
    if (tablePermission['sma' + btnType] === '是') {
      return true;
    } else {
      this.translate.get('common').subscribe(res => {
        this.toastr.error(`您無${res[btnType]}權限`, '權限', { timeOut: 2000 });
      });
      return false;
    }
    // return true;
  }

  checkAllTablePermisson(tablesList: Array<string>) {
    const permissionList = this.userService.currentUser.userPermission;
    console.log(permissionList);
    const returnPermissonTableList: any = [];
    tablesList.forEach(tName => {
      const tablePermission = permissionList.find(t => t.sma05 === tName.toUpperCase());
      if (!tablePermission) { // 沒有找到此Table名稱
        returnPermissonTableList.push({ table: tName, permission: false});
      } else if (tablePermission['sma07'] === '是') {
        returnPermissonTableList.push({ table: tName, permission: true});
      } else if (tablePermission['sma07'] === '否') {
        returnPermissonTableList.push({ table: tName, permission: false});
      }
      // returnPermissonTableList.push({ table: tName, permission: true}); // 測試用，權限全開
    });
    return returnPermissonTableList;
  }

  checkOneTablePermisson(tableName: string) {
    const tablePermission = this.userService.currentUser.userPermission.find(sma => sma.sma05.toUpperCase() === tableName.toUpperCase());
    if (!tablePermission) {
      return false; // 無此table
    } else {
      if (tablePermission.sma07 === '是') {
        return true; // 有執行權限
      } else {
        return false; // 無執行權限
      }
    }
  }

}
