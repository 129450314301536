import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface CARTPRODUCT {
  info: any;
  amount: any;
}

interface SITESETTINS {
  siteNameText: any;
  siteNameImg: any;
  siteLogo: any;
}

@Injectable({
  providedIn: 'root'
})
export class FrontGlobalService {

  private isAppCalling$ = new BehaviorSubject<boolean>(false);

  /** Site Settings **/
  private siteSettings$ = new BehaviorSubject<SITESETTINS>({ siteNameText: null, siteNameImg: null, siteLogo: null });

  /**
   * 1. product
   * 2. others
  */
  private pageMode$ = new BehaviorSubject<string>('product');

  /** Cart Info **/
  private cartAmount$ = new BehaviorSubject<number>(0);
  private cartList: Array<CARTPRODUCT> = [];

  /** 目前的Breadcrumb **/
  private currentBreadcrumb$ = new BehaviorSubject<any>({});

  constructor(
    private apiService: ApiService,
  ) {
    this.apiService.getList('ssmo', {table: 'ssmo', source: 'front-end'}).subscribe((res: any) => {
      if (res.data.length !== 0) {
        const info = res.data[0];
        const tmp: SITESETTINS = {
          siteNameText: info.smo03,
          siteNameImg: info.smo04,
          siteLogo: info.smo05
        };
        this.siteSettings$.next(tmp);
      }
    });
  }

  setisAppCalling(mode: boolean) {
    this.isAppCalling$.next(mode);
  }

  getisAppCalling(): Observable<boolean> {
    return this.isAppCalling$;
  }

  getSiteSettings(): Observable<SITESETTINS> {
    return this.siteSettings$;
  }

  setPageMode(mode: string) {
    this.pageMode$.next(mode);
  }

  getPageMode(): Observable<string> {
    return this.pageMode$;
  }

  setCartProduct(product, amount) {
    const tmp = this.cartList.find((p, idx) => {
      if (p.info.pid === product.pid) {
        if (amount === 0) {
          this.cartList.splice(idx, 1);
        } else {
          this.cartList[idx].amount = amount;
        }
      }
      return p.info.pid === product.pid;
    });

    if (!tmp) {
      this.cartList.push({
        info: product,
        amount: amount
      });
    }

    // 以數量累計
    // let cart_amount = 0;
    // this.cartList.forEach(p => {
    //   if (p.amount) { cart_amount = cart_amount + p.amount; }
    // });
    // this.cartAmount$.next(cart_amount);

    // 以種類累計
    this.cartAmount$.next(this.cartList.length);
  }

  clearCart() {
    this.cartList = [];
    this.cartAmount$.next(0);
  }

  getCartProduct() {
    return this.cartList;
  }

  getCartAmount(): Observable<number> {
    return this.cartAmount$;
  }

  setCurrentBreadcrumb(typeObj) {
    this.currentBreadcrumb$.next(typeObj);
  }

  getCurrentBreadcrumb() {
    return this.currentBreadcrumb$;
  }

}
