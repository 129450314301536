import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Modules
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedMaterialModule } from './share/shared-material/shared-material.module';
import { SharedFormModule } from './share/shared-form/shared-form.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { AngularDraggableModule } from 'angular2-draggable';
import { NgxGalleryModule } from 'ngx-gallery';
import { MatDialogModule } from '@angular/material';

// Components
import { NavbarComponent } from './share/navbar/navbar.component';
import { FrontNavbarComponent } from './share/front-navbar/front-navbar.component';
import { SelectListDialogComponent } from './share/select-list-dialog/select-list-dialog.component';
import { CopyDialogComponent } from './pages/module-product/pdma/copy-dialog/copy-dialog.component';
import { SearchProductDialogComponent } from './pages/module-product/search-product-dialog/search-product-dialog.component';
import { DeleteDialogComponent } from './pages/dialog/delete-dialog/delete-dialog.component';
import { ChooseSortDialogComponent } from './pages/module-product/pdma/choose-sort-dialog/choose-sort-dialog.component';
import { CartListDialogComponent } from './share/navbar/cart-list-dialog/cart-list-dialog.component';
// tslint:disable-next-line:max-line-length
import { PickListConfirmDialogComponent } from './pages/module-role/role-tally/dialog/pick-list-confirm-dialog/pick-list-confirm-dialog.component';
// tslint:disable-next-line:max-line-length
import { PickListPrintDialogComponent } from './pages/module-role/role-tally/dialog/pick-list-print-dialog/pick-list-print-dialog.component';
import { CommonDialogComponent } from './pages/dialog/common-dialog/common-dialog.component';

// Services
import { StartupService } from './services/startup.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '../../node_modules/@ngx-translate/core';

// Others
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { BatchUploadDialogComponent } from './pages/module-product/pdma/batch-upload-dialog/batch-upload-dialog.component';
import { ImgLazyLoadDirective } from './directives/img-lazy-load.directive';

export function startupServiceFactory(startupService: StartupService): Function { return () => startupService.load(); }
export function jwtTokenGetter() { return localStorage.getItem('backend_token'); }
// 建立TranslateHttpLoader作為語系檔的讀取器
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FrontNavbarComponent,
    SelectListDialogComponent,
    CopyDialogComponent,
    SearchProductDialogComponent,
    DeleteDialogComponent,
    ChooseSortDialogComponent,
    CartListDialogComponent,
    PickListConfirmDialogComponent,
    PickListPrintDialogComponent,
    BatchUploadDialogComponent,
    CommonDialogComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedMaterialModule,
    SharedFormModule,
    NgxLoadingModule.forRoot({}),
    ToastrModule.forRoot(),
    AngularDraggableModule,
    NgxGalleryModule,
    MatDialogModule,
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SelectListDialogComponent,
    CopyDialogComponent,
    SearchProductDialogComponent,
    DeleteDialogComponent,
    ChooseSortDialogComponent,
    CartListDialogComponent,
    PickListConfirmDialogComponent,
    PickListPrintDialogComponent,
    CommonDialogComponent,
    BatchUploadDialogComponent
  ]
})
export class AppModule { }
