<button type="btn" class="btn btn-secondary btn-sm" style="float: right;" (click)="closeDialog()"><i class="fas fa-times"></i></button>
<button *ngIf="currentPage === '2' && !isFromBackend" type="btn" class="btn btn-primary btn-sm" style="float: left;" (click)="currentPage = '1';">上一頁</button>
<h4 *ngIf="currentPage === '1';" mat-dialog-title class="text-center" style="color: #0056b3;">購物車商品</h4>
<h4 *ngIf="currentPage === '2';" mat-dialog-title class="text-center" style="color: #0056b3;">付款資料</h4>

<ng-container *ngIf="currentPage === '1';">
  <ng-container *ngIf="callerCartList.length">
    <div class="cus-row">
      <div class="col pl-0" style="max-height: 70vh; overflow: auto;">
        <div class="s-pcontainer-list" *ngFor="let product of callerCartList;">
          <div class="cus-row">
            <div class="list-img-container">
              <img [src]="product.img ? product.img : ''" alt="">
            </div>
            <div class="col">
              <div>品號：{{ product.tmod07 }}</div>
              <div>品名: {{ product.tmod08 }}</div>
              <div>價格: NT${{ product.tmod09 }}</div>
              <div>購買數量: {{ product.tmod11 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-45 p-0">
        <div style="margin-right: 10px; font-size: 18px;">
          <span style="font-weight: bold;">姓名：</span>{{ callerInfo.uname }}
        </div>
        <div style="margin-right: 10px; font-size: 18px;">
          <span style="font-weight: bold;">國籍：</span>{{ callerInfo.language }}
        </div>
        <div style="margin-right: 10px; font-size: 18px;" *ngIf="callerInfo.role === 'member'">
          <span style="font-weight: bold;">電子錢包餘額：</span>NT${{ myfinalEmoney }}
        </div>
        <hr>

        <div style="font-size: 18px; font-weight: bold;">購買總額<span style="float: right;">NT${{ totalPrice }}</span></div>


        <div style="font-size: 18px; clear: both;" *ngIf="totalPriceWithoutCard >= 1500 && myEmoney >= 100">
          <!-- <span style="float: right;" *ngIf="!isDiscount">-NT$0</span> -->
          <span style="float: right;" *ngIf="isDiscount">-NT$100</span>
          <div *ngIf="isDiscount">自動折抵NT$100購物金</div>
          <!-- <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="isDiscount" (change)="getFinalAmount()">
            <label class="form-check-label" for="exampleCheck1">是否折抵NT$100購物金</label>
          </div> -->
        </div>
        <!-- <div style="font-size: 18px;" *ngFor="let mcoupon of memberCoupon">
          <ng-container *ngIf="mcoupon.smu03 === '優惠券'">
            編號{{ mcoupon.mmc106 }}{{ mcoupon.smu03 }}折抵NT${{ mcoupon.smu08 }}/次
            <select class="form-control form-control-sm" style="display: inline-block; width: 55px;">
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
            <span style="color: red; float: right;">-NT$ 100</span>
          </ng-container>
          <ng-container *ngIf="mcoupon.smu03 === '優惠券'">
            編號{{ mcoupon.mmc106 }}{{ mcoupon.smu03 }}折抵NT${{ mcoupon.smu08 }}/張
            <div class="form-group form-check" style="display: inline-block;">
              <input type="checkbox" class="form-check-input" id="exampleCheck1">
            </div>
            <span style="color: red; float: right;">-NT$ 100</span>
          </ng-container>
        </div> -->
        <hr class="my-1">
        <div style="font-size: 18px; font-weight: bold;">應繳金額<span style="color: red; float: right;">NT${{ finalCartPrice }}</span></div>

        <div *ngIf="callerInfo.role === 'guest'" style="margin-right: 10px; font-size: 18px; color: red;">此用戶為訪客，請先註冊成為會員</div>
        <button *ngIf="callerInfo.role === 'guest'" type="button" class="btn btn-danger" (click)="closeDialog('register')">會員註冊</button>
        <div class="text-right" *ngIf="callerInfo.role === 'member'">
          <button type="button" class="btn btn-danger" (click)="currentPage = '2';">結帳</button>
        </div>
        <hr>

        <!-- <div *ngIf="fullAmountCoupon">
          <div style="font-size: 18px; font-weight: bold;">此次購物可獲得</div>
          <div style="font-size: 18px;" *ngIf="fullAmountCoupon.smu03 === '優惠券'">滿額 NT${{ fullAmountCoupon.smu05 }}，送 NT${{ fullAmountCoupon.smu11 }}{{ fullAmountCoupon.smu03 }} x 1</div>
          <div style="font-size: 18px;" *ngIf="fullAmountCoupon.smu03 === '購物金'">滿額 NT${{ fullAmountCoupon.smu05 }}，送 NT${{ fullAmountCoupon.smu11 }}{{ fullAmountCoupon.smu03 }}</div>
        </div> -->
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!callerCartList.length">
    <h4>無商品</h4>
  </ng-container>
</ng-container>

<ng-container *ngIf="currentPage === '2';">
  <div class="cus-row">
    <div class="col-7">
      <form [formGroup]="myForm">
        <div class="checkout-info-container text-left" style="margin-top: 0;">
          <div class="checkout-info-tag">選擇配送方式</div>
          <!-- <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="delivery" id="delivery1" value="超商" [formControl]="myForm.controls['dmo10']">
            <label class="form-check-label" for="delivery1">
              超商取貨
            </label>
          </div> -->
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="delivery" id="delivery2" value="貨運" [formControl]="myForm.controls['dmo10']">
            <label class="form-check-label" for="delivery2">
              宅配到府
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="delivery" id="delivery3" value="店購" [formControl]="myForm.controls['dmo10']">
            <label class="form-check-label" for="delivery3">
              玉凰店點取貨
            </label>
          </div>
          <ng-container *ngIf="myForm.get('dmo10').value === '超商'">
            <div class="form-inline py-2">
              <div class="col-12 p-0 form-group my-2">
                <div class="col-6 p-0">
                  <select #formElm class="form-control form-control-sm w-100"
                    [ngClass]="{'error-input':!myForm.controls['dmo11'].valid && (myForm.controls['dmo11'].touched || isClickConfirmBtn)}"
                    [formControl]="myForm.controls['dmo11']">
                    <option value="">選擇超商</option>
                    <option value="7-11">7-11</option>
                    <option value="全家">全家</option>
                    <option value="OK">OK</option>
                    <option value="萊爾富">萊爾富</option>
                  </select>
                  <small *ngIf="myForm.controls['dmo11'].hasError('required') && (myForm.controls['dmo11'].touched || isClickConfirmBtn)" class="error-feedback">
                    {{ 'error.require' | translate }}</small>
                </div>
              </div>
              <div class="col-12 p-0 form-group my-2">
                <div class="col-6 p-0">
                  <select #formElm class="form-control form-control-sm w-100"
                    [ngClass]="{'error-input':!myForm.controls['dmo13'].valid && (myForm.controls['dmo13'].touched || isClickConfirmBtn)}"
                    [formControl]="myForm.controls['dmo13']">
                    <option value="">選擇門市</option>
                    <option value="南京門市">南京門市</option>
                  </select>
                  <small *ngIf="myForm.controls['dmo13'].hasError('required') && (myForm.controls['dmo13'].touched || isClickConfirmBtn)" class="error-feedback">
                    {{ 'error.require' | translate }}</small>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="myForm.get('dmo10').value === '店購'">
            <div class="form-inline pt-2">
              <div class="col-12 p-0 form-group">
                <div class="col-6 p-0">
                  <select #formElm class="form-control form-control-sm w-100"
                    [ngClass]="{'error-input':!myForm.controls['dmo15'].valid && (myForm.controls['dmo15'].touched || isClickConfirmBtn)}"
                    [formControl]="myForm.controls['dmo15']">
                    <option value="">選擇店點</option>
                    <option value="桃園店">桃園店</option>
                    <option value="台中店">台中店</option>
                    <option value="台南店">台南店</option>
                    <option value="高雄店">高雄店</option>
                  </select>
                  <small *ngIf="myForm.controls['dmo15'].hasError('required') && (myForm.controls['dmo15'].touched || isClickConfirmBtn)" class="error-feedback">
                    {{ 'error.require' | translate }}</small>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="checkout-info-container text-left">
          <div class="checkout-info-tag">選擇付款方式</div>
          <!-- <div class="form-check">
            <input class="form-check-input" type="radio" name="pay" id="pay1" value="超商先付款後取貨" [formControl]="myForm.controls['payType']">
            <label class="form-check-label" for="pay1">
              超商先付款後取貨
              <small style="color: red;" *ngIf="myForm.get('dmo10').value === '超商'">超商繳費後，等待貨物送至指定超商</small>
              <small style="color: red;" *ngIf="myForm.get('dmo10').value === '貨運'">超商繳費後，等待貨物送至指定地址</small>
              <small style="color: red;" *ngIf="myForm.get('dmo10').value === '店購'">超商繳費後，至指定玉凰店點取貨</small>
            </label>
          </div>
          <div class="form-check" *ngIf="myForm.get('dmo10').value === '超商'">
            <input class="form-check-input" type="radio" name="pay" id="pay2" value="超商取貨付款" [formControl]="myForm.controls['payType']">
            <label class="form-check-label" for="pay2">
              超商取貨付款
              <small style="color: red;">貨物送達指定超商後，至超商繳費並取貨</small>
            </label>
          </div> -->
          <div class="form-check" *ngIf="myForm.get('dmo10').value === '貨運'">
            <input class="form-check-input" type="radio" name="pay" id="pay3" value="貨到付款" [formControl]="myForm.controls['payType']">
            <label class="form-check-label" for="pay3">
              貨到付款
              <small style="color: red;">貨物送達指定地址後，繳費並取貨</small>
            </label>
          </div>
          <div class="form-check" *ngIf="myForm.get('dmo10').value === '店購'">
            <input class="form-check-input" type="radio" name="pay" id="pay4" value="玉凰店點繳費" [formControl]="myForm.controls['payType']">
            <label class="form-check-label" for="pay4">
              玉凰店點繳費
              <small style="color: red;">至指定的玉凰店點，繳費並取貨</small>
            </label>
          </div>
        </div>
        <div class="checkout-info-container">
          <div class="checkout-info-tag">訂購人資料</div>
          <div class="form-inline py-2">
            <div class="col-12 p-0 form-group my-2">
              <div class="col-6 p-0">
                <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="輸入姓名" [value]="memberInfo?.mma06" readonly>
              </div>
            </div>
            <div class="col-12 p-0 form-group my-2">
              <div class="col-6 p-0">
                <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="輸入手機號碼" [value]="memberInfo?.mma26" readonly>
              </div>
            </div>
            <div class="col-12 p-0 form-group my-2">
              <div class="col-8 p-0">
                <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="輸入聯絡地址" [value]="memberInfo?.mma22" readonly>
              </div>
            </div>
          </div>
        </div>
        <div class="checkout-info-container">
          <div class="checkout-info-tag">收件人資料
            <div class="form-check" style="display: inline-block; font-size: 16px; margin-left: 20px;">
              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" [formControl]="myForm.controls['isSame']">
              <label class="form-check-label" for="defaultCheck1">同訂購人資料</label>
            </div>
          </div>
          <div class="form-inline py-2">
            <div class="col-12 cus-row p-0 form-group mt-0 mb-2">
              <div class="col-6 pr-1 pl-0">
                <div class="col-12 p-0">
                  <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="輸入姓名"
                    [ngClass]="{'error-input':!myForm.controls['receiver_name'].valid && (myForm.controls['receiver_name'].touched || isClickConfirmBtn)}"
                    [formControl]="myForm.controls['receiver_name']">
                </div>
                <small *ngIf="myForm.controls['receiver_name'].hasError('required') && (myForm.controls['receiver_name'].touched || isClickConfirmBtn)" class="error-feedback">
                  {{ 'error.require' | translate }}</small>
                <small *ngIf="myForm.controls['receiver_name'].hasError('minlength') && (myForm.controls['receiver_name'].touched || isSaveBtnClicked)" class="error-feedback">
                  {{ 'error.size' | translate: {'size': '30'} }}</small>
              </div>
              <div class="col-6 pl-1 pr-0">
                <div class="col-12 p-0">
                  <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="輸入手機號碼" maxlength="11" minlength="11"
                    [ngClass]="{'error-input':!myForm.controls['receiver_phone'].valid && (myForm.controls['receiver_phone'].touched || isClickConfirmBtn)}"
                    [formControl]="myForm.controls['receiver_phone']"
                    (input)="inputVerifyService.checkMobile($event)">
                </div>
                <small *ngIf="myForm.controls['receiver_phone'].hasError('required') && (myForm.controls['receiver_phone'].touched || isClickConfirmBtn)" class="error-feedback">
                  {{ 'error.require' | translate }}</small>
                <small *ngIf="myForm.controls['receiver_phone'].hasError('minlength') && (myForm.controls['receiver_phone'].touched || isSaveBtnClicked)" class="error-feedback">
                  {{ 'error.size' | translate: {'size': '10'} }}</small>
              </div>
            </div>
            <div class="col-12 p-0 form-group my-2" *ngIf="myForm.get('dmo10').value === '貨運'">
              <div class="col-8 p-0">
                <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="輸入到貨地址"
                  [ngClass]="{'error-input':!myForm.controls['dmo16'].valid && (myForm.controls['dmo16'].touched || isClickConfirmBtn)}"
                  [formControl]="myForm.controls['dmo16']">
                <small *ngIf="myForm.controls['dmo16'].hasError('required') && (myForm.controls['dmo16'].touched || isClickConfirmBtn)" class="error-feedback">
                  {{ 'error.require' | translate }}</small>
                <small *ngIf="myForm.controls['dmo16'].hasError('maxlength') && (myForm.controls['dmo16'].touched || isSaveBtnClicked)" class="error-feedback">
                  {{ 'error.maxTextlength' | translate: {'maxLength': '50'} }}</small>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-5">
      <div style="margin-right: 10px; font-size: 20px; font-weight: bold;">姓名： <span style="color: red">{{ callerInfo.uname }}</span></div>
      <!-- <div style="margin-right: 10px; font-size: 20px; font-weight: bold;">電子錢包餘額： <span style="color: red">NT${{ memberInfo.mma27 }}</span></div> -->
      <!-- <div style="margin-right: 10px; font-size: 20px; font-weight: bold;">使用電子錢包： <span style="color: red">NT$123</span></div> -->
      <div style="margin-right: 10px; font-size: 20px; font-weight: bold;">購買總額： <span style="color: red">NT${{ totalPrice }}</span></div>
      <div style="margin-right: 10px; font-size: 20px; font-weight: bold;">應繳金額： <span style="color: red">NT${{ finalCartPrice }}</span></div>
      <button type="button" class="btn btn-danger" (click)="createOrder(myForm.getRawValue())">確認結帳</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentPage === '3';">
  <h4>訂單創建成功</h4>
</ng-container>

<ngx-loading [show]="loading"></ngx-loading>

