import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FoucsService {

  constructor() { }

  elmFocus(elm, time) {
    setTimeout(() => {
      elm.nativeElement.focus();
    }, time);
  }

}
