import { FrontGlobalService } from './services/front-global.service';
import { SipService } from './services/sip.service';
import { Observable } from 'rxjs';
import { GlobalService } from './services/global.service';
import { LanguageService } from './services/language.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'intw-main-panel';
  webMode$: Observable<number>;
  isDoucmentHeightOver$: Observable<boolean>;

  pageLoading$: Observable<boolean>;

  isAppCalling$: Observable<boolean>;

  constructor(
    private frontGlobalService: FrontGlobalService,
    public sipService: SipService,
    public globalService: GlobalService,
    private languageService: LanguageService
  ) {
    this.languageService.setInitState();
    this.pageLoading$ = this.globalService.getPageLoading();
    this.webMode$ = this.globalService.getWebMode();
    this.isAppCalling$ = this.frontGlobalService.getisAppCalling();
    // this.isDoucmentHeightOver$ = this.globalService.getIsDoucmentHeightOver();
  }

}
