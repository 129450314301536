import { Response } from './../../models/response';
import { PermissionService } from './../../services/permission.service';
import { SipService } from './../../services/sip.service';
import { LanguageService } from './../../services/language.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UserService } from './../../services/user.service';
import { appPath } from './../../app-path.const';
import { LangChangeEvent } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { ApiService } from '../../services/api.service';
import { DateService } from '../../services/date.service';
import { MatDialog, MatDialogRef } from '@angular/material';
// Component
import { CartListDialogComponent } from './cart-list-dialog/cart-list-dialog.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  // Observers
  currentUser$: Subscription;
  currentUser: any;

  login$: Subscription;
  login: any;

  commingCall$: Subscription;
  callerInfo: any;
  callerCartList = [];

  buttonMode$: Observable<number>;
  language$: Observable<LangChangeEvent>;
  flag$: Observable<string>;
  // commingCall$: Observable<boolean>;
  // b_isAcceptedCall$: Observable<boolean>;
  b_isAcceptedCall$: Subscription;
  b_isAcceptedCall: any;
  languageList = [];

  // Customer Service
  isCSPermission = false;
  isCSOnline = false;
  changingStatusFromServer = false;

  // Unread Message
  unreadMessageTotal = 0;
  unreadMessageList = [];
  timeout_unredMessage: any;

  // Unread Order
  unreadOrderTotal = 0;
  unreadOrderList = [];
  timeout_unredOrder: any;

  // Dialog
  dialogRef_cartList: MatDialogRef<CartListDialogComponent>;

  // 國家對照
  countryMap = new Map()
  .set('en', '英文')
  .set('zh-tw', '台灣')
  .set('ph', '菲律賓')
  .set('vn', '越南')
  .set('id', '印尼')
  .set('th', '泰國');

  // 來源頁面對照
  sourceMap = new Map()
  .set('regist', '註冊頁面')
  .set('cart', '結帳頁面');

  // 員工 SIP 帳密
  sipAccount = {
    'admin': {
      uri: 'gowint@gowint.onsip.com',
      authorizationUser: 'gowint',
      password: 'mKLYF6fS9STDZVEB',
    },
    'E190830888': {
      uri: 'walter458888@gowint.onsip.com',
      authorizationUser: 'gowint_walter458888',
      password: 'dnF9nvhyMqHcXMzd'
    },
    'E190822001': {
      uri: 'bequeen.lin@gowint.onsip.com',
      authorizationUser: 'gowint_bequeen_lin',
      password: 'KW77QWpEqEqRD8b4'
    },
    'E190822002': {
      uri: 'bequeen.hsu@gowint.onsip.com',
      authorizationUser: 'gowint_bequeen_hsu',
      password: 'm8bJQBZADxMN65gC'
    },
    'E190822003': {
      uri: 'bequeen.wang@gowint.onsip.com',
      authorizationUser: 'gowint_bequeen_wang',
      password: 'kgAkUbeCHagSdV7C'
    },
  };

  constructor(
    public dialog: MatDialog,
    private dateService: DateService,
    private api: ApiService,
    private permissionService: PermissionService,
    private sipService: SipService,
    public globalService: GlobalService,
    private router: Router,
    private languageService: LanguageService,
    private userService: UserService,
  ) {
    const that = this;
    // Trigger when windows is going to be closed
    window.addEventListener('beforeunload', (event) => {
      console.log('window beforeunload');
      if (this.isCSOnline) {
        console.log('customer service log out');
        that.doCSlogout();
      }
      event.returnValue = `Are you sure you want to leave?`;
    });

    // this.openCartListDialog();
  }

  ngOnInit() {
    this.currentUser$ = this.userService.getCurrentUser().subscribe(res => {
      if (res) {
        this.currentUser = res.userInfo;
        console.log('=======================');
        console.log(this.currentUser);


        // 判斷是否有客服權限
        this.isCSPermission = this.permissionService.checkOneTablePermisson('SSMT');
        console.log('========== Is cs permission ==========');
        console.log(this.isCSPermission);
      }
    });

    this.login$ = this.userService.getLoginStatus().subscribe(res => {
      this.login = res;

      if (this.login) {
        // 登入後，取得未讀訊息
        // this.getUnreadMessage();
        // this.timeout_unredMessage = setInterval(() => {
        //   this.getUnreadMessage();
        // }, 8000);

        // this.getUnreadOrder();
        // this.timeout_unredOrder = setInterval(() => {
        //   this.getUnreadOrder();
        // }, 8000);
      }
    });

    this.buttonMode$ = this.globalService.getButtonMode();
    this.language$ = this.languageService.getCurrentLanguage();
    this.flag$ = this.languageService.getCurrentFlag();

    this.commingCall$ = this.sipService.getCommingStatus().subscribe(res => {
      if (res) {
        console.log('=============== Caller Information ==============');
        console.log(res);
        const tmp = {
          uname: res.uname,
          language: this.countryMap.get(res.language),
          source: this.sourceMap.get(res.source),
          role: res.role,
          uid: res.uid,
          osid: res.osid,
          member: res.member ? res.member : ''
        };
        this.callerInfo = tmp;
        console.log('=============== Caller Information ==============');
        console.log(this.callerInfo);


        let userId = '';
        let guestId = '';
        if (this.callerInfo.role === 'member') {
          userId = this.callerInfo.uid;
        } else if (this.callerInfo.role === 'guest') {
          guestId = this.callerInfo.uid;
        }
        // 取出暫存的購物車資料
        this.api.apost('tpdmod/list', {userId, guestId}).subscribe((resp: any) => {
          console.log(resp);
          this.callerCartList = resp.payload;
        });
      } else {
        this.callerInfo = null;
        this.callerCartList = [];
      }
    });

    this.b_isAcceptedCall$ = this.sipService.getBackAcceptedCallStatus().subscribe(res => {
      this.b_isAcceptedCall = res;

      if (this.currentUser) {
        if (res) {
          this.api.post('/ssmts/busy', {sts04: this.currentUser.smi03}).subscribe((resp: Response) => {
            console.log(resp.payload);
          }, err => {
            alert('網路錯誤');
          });
        } else {
          this.api.post('/ssmts/idle', {sts04: this.currentUser.smi03}).subscribe((resp: Response) => {
            console.log(resp.payload);
          }, err => {
            alert('網路錯誤');
          });
        }
      }
    });
    this.languageList = this.languageService.translate.langs;


    // this.openCartListDialog();
  }

  ngOnDestroy(): void {
    this.currentUser$.unsubscribe();
    this.login$.unsubscribe();
    this.commingCall$.unsubscribe();
    this.b_isAcceptedCall$.unsubscribe();
    if (this.timeout_unredMessage) {
      clearInterval(this.timeout_unredMessage);
    }
    if (this.timeout_unredOrder) {
      clearInterval(this.timeout_unredOrder);
    }
  }

  /***** Buttons *****/
  logout() {
    this.userService.logout();
    this.router.navigate(['/' + appPath.login]);
    if (this.timeout_unredMessage) {
      clearInterval(this.timeout_unredMessage);
    }
    if (this.timeout_unredOrder) {
      clearInterval(this.timeout_unredOrder);
    }
  }

  /***** SIP *****/
  answer() {
    this.sipService.answer();
  }
  hangup() {
    this.sipService.hangup('backend');
  }
  openCartListDialog() {
    this.dialogRef_cartList = this.dialog.open(CartListDialogComponent, {
      maxHeight: '85vh',
      minWidth: '75vw',
      position: { top: '10vh' },
      data: {
        callerInfo: this.callerInfo,
        callerCartList: this.callerCartList,
      }
    });
    this.dialogRef_cartList.afterClosed().subscribe(res => {
      console.log(res);
      if (res === 'register') {
        this.goRegisterPage(this.callerInfo.uid);
      } else {
        // this.mytoastr.error(2);
      }
    });
  }

  /***** GO *****/
  goFrontend() {
    this.router.navigate(['/' + appPath.front.home]);
  }
  goHomePage() {
    this.router.navigate(['/' + appPath.homePage]);
  }
  goSSMTPage(uid) {
    console.log(uid);
    this.router.navigate(['/module-system/ssmt', uid]);
  }
  goPDMOPage(oid) {
    console.log(oid);
    this.router.navigate(['/module-product/pdmo', oid]).then(() => {
      this.readOrder(oid);
    });
  }
  goMemberPage() {
    this.router.navigate(['/module-member/smma']);
  }
  goRegisterPage(guestId = '') {
    this.router.navigate(['/module-member/addmember/' + guestId]);
  }

  /***** Functions *****/
  getFlag(language: string) {
    return this.languageService.countryMap.get(language);
  }
  changeLanguage(language: string) {
    this.languageService.setLang(language);
  }
  switchCS() {
    this.changingStatusFromServer = true;
    if (this.isCSOnline) {
      this.api.post('/ssmts/login', {
        sts03: this.dateService.YYYYMMDDTHHMMSS(new Date()),
        sts04: this.currentUser.smi03,
        sts05: this.currentUser.smi04,
        sys991: this.currentUser.smi04,
        sys992: this.dateService.YYYYMMDD(new Date()),
      }).subscribe((res: Response) => {
        this.changingStatusFromServer = false;
        if (!res.success) {
          this.isCSOnline = false;
        } else {
          // 連接SIP
          this.sipService.backendSipStart(this.sipAccount[this.currentUser.smi03]);
        }
        console.log(res.payload);
      }, err => {
        this.changingStatusFromServer = false;
        alert('網路錯誤');
      });
    } else {
      this.doCSlogout();

      // 中斷SIP
      this.sipService.backendSipOff();
    }
  }
  doCSlogout() {
    this.api.post('/ssmts/logout', {
      sts04: this.currentUser.smi03,
      sts05: this.currentUser.smi04,
      sts06: this.dateService.YYYYMMDDTHHMMSS(new Date()),
      sys993: this.currentUser.smi04,
      sys994: this.dateService.YYYYMMDD(new Date()),
    }).subscribe((res: Response) => {
      this.changingStatusFromServer = false;
      if (!res.success) {
        this.isCSOnline = true;
      }
      console.log(res.payload);
    }, err => {
      this.changingStatusFromServer = false;
      alert('網路錯誤');
    });
  }
  getUnreadMessage() {
    this.api.post('ssmt/unreadMessage', {}).subscribe((resp: Response) => {
      if (resp.success) {
        console.log(resp.payload);
        this.unreadMessageTotal = resp.payload.total.count;

        const tmp = resp.payload.messageList;
        tmp.forEach(e => {
          e.smt03 = YYYYMMDDTHHMM2(e.smt03);
        });
        this.unreadMessageList = tmp;
      }
    });
  }
  getUnreadOrder() {
    this.api.post('pdmo/unreadOrder', {}).subscribe((resp: Response) => {
      if (resp.success) {
        console.log(resp.payload);
        this.unreadOrderTotal = resp.payload.total.count;

        const tmp = resp.payload.orderList;
        tmp.forEach(e => {
          e.dmo03 = YYYYMMDD(e.dmo03);
        });
        this.unreadOrderList = tmp;
      }
    });
  }
  readOrder(oid) {
    this.api.post('pdmo/readOrder', { dmo04: oid }).subscribe((res: Response) => {
      console.log(res);
    });
  }
}

function YYYYMMDD(rawDate) {
  const tempDate = new Date(rawDate);
  const year = tempDate.getFullYear();
  let month = (1 + tempDate.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;
  let day = tempDate.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  return year + '-' + month + '-' + day;
}

function YYYYMMDDTHHMM2(rawDate) {
  const tempDate = new Date(rawDate);
  const year = tempDate.getFullYear();
  let month = (1 + tempDate.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;
  let day = tempDate.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  let hour = tempDate.getHours().toString();
  hour = hour.length > 1 ? hour : '0' + hour;
  let minute = tempDate.getMinutes().toString();
  minute = minute.length > 1 ? minute : '0' + minute;
  return year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
}
