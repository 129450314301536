import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

export interface ImgCompress {
  base64?: string;
  blob?: Blob;
}

@Injectable({
  providedIn: 'root'
})
export class ImgService {

  // 電商-商品圖片
  ecImg_p = {}; // product img

  private renderer: Renderer2;
  private maxImgSize = 500 * 1000;

  constructor(
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /***** 儲存電商圖片 *****/
  storeEcImgP(e, pid) {
    this.ecImg_p[pid] = e;
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
    } else {
        byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }

  blobtoDataURL(blob, callback) {
    const fr = new FileReader();
    fr.onload = function(e) {
      // callback(e.target.result);
      callback(fr.result);
    };
    fr.readAsDataURL(blob);
  }

  base64ArrayBuffer(arrayBuffer) {
    let base64    = '';
    const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    const bytes         = new Uint8Array(arrayBuffer);
    const byteLength    = bytes.byteLength;
    const byteRemainder = byteLength % 3;
    const mainLength    = byteLength - byteRemainder;

    let a, b, c, d;
    let chunk;

    // Main loop deals with bytes in chunks of 3
    for (let i = 0; i < mainLength; i = i + 3) {
      // Combine the three bytes into a single integer
      // tslint:disable-next-line:no-bitwise
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

      // Use bitmasks to extract 6-bit segments from the triplet
      // tslint:disable-next-line:no-bitwise
      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      // tslint:disable-next-line:no-bitwise
      b = (chunk & 258048)   >> 12; // 258048   = (2^6 - 1) << 12
      // tslint:disable-next-line:no-bitwise
      c = (chunk & 4032)     >>  6; // 4032     = (2^6 - 1) << 6
      // tslint:disable-next-line:no-bitwise
      d = chunk & 63;               // 63       = 2^6 - 1

      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
      chunk = bytes[mainLength];

      // tslint:disable-next-line:no-bitwise
      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

      // Set the 4 least significant bits to zero
      // tslint:disable-next-line:no-bitwise
      b = (chunk & 3)   << 4; // 3   = 2^2 - 1

      base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder === 2) {
      // tslint:disable-next-line:no-bitwise
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

      // tslint:disable-next-line:no-bitwise
      a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
      // tslint:disable-next-line:no-bitwise
      b = (chunk & 1008)  >>  4; // 1008  = (2^6 - 1) << 4

      // Set the 2 least significant bits to zero
      // tslint:disable-next-line:no-bitwise
      c = (chunk & 15)    <<  2; // 15    = 2^4 - 1

      base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }

    return base64;
  }

  /***** 圖片壓縮 *****/
  read(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
    });
  }
  compress(imageDataUrlSource: any, render: Renderer2, ratio?: number, quality?: number): Promise<string> {
    ratio = ratio ? ratio : 1;
    quality = quality ? quality : 0.5;

    return new Promise((resolve, reject) => {
      const sourceImage = new Image();
      // important for safari: we need to wait for onload event
      sourceImage.onload = function () {
        const canvas: HTMLCanvasElement = render.createElement('canvas');
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

        canvas.width = sourceImage.naturalWidth * ratio;
        canvas.height = sourceImage.naturalHeight * ratio;

        ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);

        const mime = imageDataUrlSource.substr(5, imageDataUrlSource[0].length);
        // TODO test on mime
        const result = canvas.toDataURL('image/jpeg', quality);

        resolve(result);

      };

      sourceImage.src = imageDataUrlSource;
    });
  }
  convertBase64UrlToBlob (urlData): Blob {
    const arr = urlData.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  doImgCompress(file: File): Promise<ImgCompress> {
    console.log(file.size);
    console.log(this.maxImgSize);

    if (file.size > this.maxImgSize) {
      const quality = this.maxImgSize / file.size;
      return new Promise((resolve, reject) => {
        this.read(file).then(srcData => {
          this.compress(srcData, this.renderer, 1, quality).then(value => {
            resolve({
              base64: 'data:image/jpeg;base64,' + value.split(',')[1], // 通通變成JPEG
              blob: this.convertBase64UrlToBlob(value)
            });
          });
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64Value = 'data:image/jpeg;base64,' + (<string>reader.result).split(',')[1]; // 通通變成JPEG
          const blobValue = this.dataURItoBlob(reader.result);
          resolve({
            base64: base64Value,
            blob: blobValue
          });
        };
      });
      // return new Promise((resolve, reject) => { resolve(file); });
    }
  }

}
