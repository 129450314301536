import { LanguageService } from './language.service';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
// Rxjs
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export interface CART_PRODUCT {
  pid: string;
  amount: number;
  totalPrice: number;
  info: PRODUCT;
}

export interface PRODUCT {
  pid: string;
  pno: string;
  pname: string;
  pprice: number;
  pbrand: string;
  plevel1no?: string;
  premark?: string;
  img?: any;
  promoprice?: number;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {

  myCartList: Array<CART_PRODUCT> = [];
  myCartList$ = new BehaviorSubject<Array<CART_PRODUCT>>([]);

  // 購物車-總金額
  totalCartPrice$ = new BehaviorSubject<number>(0);
  totalCartPrice = 0;
  // 購物車-扣除"電話卡"總金額 (ps. 電話卡不適用免運門檻 & 使用購物金)
  totalCartPriceWithoutCard$ = new BehaviorSubject<number>(0);
  totalCartPriceWithoutCard = 0;

  // 購物車總種類數量
  totalCartSort$ = new BehaviorSubject<number>(0);

  // 購物車總產品數量
  totalProductAmount = 0;
  totalProductAmount$ = new BehaviorSubject<number>(0);

  constructor(
    private api: ApiService,
    private languageServices: LanguageService,
  ) { }

  initCart(userId = '', guestId = '', memberInfo: any = '') { // When app start
    this.languageServices.getCurrentLanguage().pipe(take(1)).subscribe(res => {
      this.api.apost('tpdmod/list', {userId, guestId, lang: res.lang}).subscribe((res: any) => {
        console.log('********** Cart List From Server **********');
        console.log('Member ID : ' + userId);
        console.log('Guest ID : ' + guestId);
        console.log(res);
  
        const cartListFromServer = res.payload;
        const tmp = [];
        let totalPrice = 0; // 購物車總價格
        let totalAmount = 0; // 購物車總數量
        let totalCartPriceWithoutCard = 0;
        if (cartListFromServer) {
          cartListFromServer.forEach(e => {
            tmp.push({
              pid: e.tmod06,
              amount: e.tmod11,
              totalPrice: e.tmod09 * e.tmod11,
              info: {
                pid: e.tmod06,
                pno: e.tmod07,
                pname: e.tmod08,
                pprice: e.tmod09,
                pbrand: e.tmod10,
                premark: e.tmod13,
                img: ''
              }
            });
            totalPrice += e.tmod09 * e.tmod11;
            totalAmount += e.tmod11;
  
            if (e.tmod13 !== '電話卡') {
              totalCartPriceWithoutCard += e.tmod09 * e.tmod11;
            }
  
          });
        }
        if (userId) {
          if (this.myCartList) {
            this.myCartList.forEach(c => {
              // 已存在商品，更新商品資料
              const exist_product = tmp.find((p, idx) => {
                if (p.pid === c.pid) {
                  tmp[idx].amount = c.amount;
                  tmp[idx].totalPrice = c.totalPrice;
                  // console.log(tmp);
                }
                return p.pid === c.pid;
              });
  
              // 未存在商品，新增商品資料
              if (!exist_product) {
                tmp.push(c);
                // console.log(tmp);
              }
  
              totalPrice = 0;
              totalAmount = 0;
              totalCartPriceWithoutCard = 0;
              tmp.forEach(p => {
                totalPrice += p.totalPrice;
                totalAmount += p.amount;
  
                if (p.info.premark !== '電話卡') {
                  totalCartPriceWithoutCard += p.totalPrice;
                }
              });
  
              const userInfo = {
                mma03: memberInfo ? memberInfo.mma03 : 0,
                mma04: memberInfo ? memberInfo.mma04 : '',
                mma05: memberInfo ? memberInfo.mma05 : '',
                mma06: memberInfo ? memberInfo.mma06 : '',
                mma10: memberInfo ? memberInfo.mma10 : '',
              }
  
              this.setCartToServer(c, userInfo);
            })
          }
        };
  
        this.totalCartPrice = totalPrice;
        this.totalProductAmount = totalAmount;
        this.totalCartPriceWithoutCard = totalCartPriceWithoutCard;
  
        this.totalCartPriceWithoutCard$.next(totalCartPriceWithoutCard);
        this.totalCartPrice$.next(totalPrice);
        this.totalProductAmount$.next(totalAmount);
        this.totalCartSort$.next(tmp.length);
        this.myCartList = tmp;
        this.myCartList$.next(tmp);
  
        console.log('********** Current Cart List **********');
        console.log(tmp);
      })
    });
  }

  setCart(input_product: PRODUCT, amount: number, userInfo) {
    console.log(input_product);
    // console.log(amount);

    if (input_product.promoprice) {
      input_product.pprice = input_product.promoprice;
    }

    if (input_product.plevel1no === 'ET001') {
      console.log(input_product);
      input_product.premark = '電話卡';
    } else {
      if (input_product.premark !== '電話卡') {
        input_product.premark = '';
      }
    }

    // 把input_product包裝成購物車cart_product格式
    const cart_prodcut:CART_PRODUCT = {
      pid: input_product.pid,
      amount,
      totalPrice: amount * input_product.pprice,
      info: input_product
    }

    // 已存在商品，更新商品資料
    const exist_product = this.myCartList.find((p, idx) => {
      if (p.pid === cart_prodcut.pid) {
        this.myCartList[idx].amount = cart_prodcut.amount;
        this.myCartList[idx].totalPrice = cart_prodcut.totalPrice;
        // console.log(this.myCartList);
      }
      return p.pid === cart_prodcut.pid;
    });

    // 未存在商品，新增商品資料
    if (!exist_product) {
      this.myCartList.push(cart_prodcut);
      // console.log(this.myCartList);
    }

    this.totalCartPrice = 0;
    this.totalProductAmount = 0;
    this.totalCartPriceWithoutCard = 0;
    this.myCartList.forEach(p => {
      this.totalCartPrice += p.totalPrice;
      this.totalProductAmount += p.amount;

      if (p.info.premark !== '電話卡') {
        this.totalCartPriceWithoutCard += p.totalPrice;
      }

    });

    this.totalCartPriceWithoutCard$.next(this.totalCartPriceWithoutCard);
    this.totalCartPrice$.next(this.totalCartPrice);
    this.totalProductAmount$.next(this.totalProductAmount);
    this.totalCartSort$.next(this.myCartList.length);
    this.myCartList$.next(this.myCartList);


    // console.log(this.myCartList, userInfo);
    // console.log(cart_prodcut);
    this.setCartToServer(cart_prodcut, userInfo);
  }

  setCartToServer(cart_product:CART_PRODUCT, userInfo) {
    // this.loadingProvider.startLoading();
    delete cart_product.info.img;
    this.api.apost('tpdmod/insert', {userInfo, cart_product}).subscribe((res: any) => {
      console.log('********** Set Cart List To Server **********');
      console.log(res);
      // this.loadingProvider.closeLoading();
    })
  }

  removeProduct(pid, userInfo) {
    const index = this.myCartList.findIndex(function (elm) {
      return elm.pid === pid;
    })

    if (index === 0 || index) {
      this.totalCartPrice -= this.myCartList[index].totalPrice;
      this.totalProductAmount -= this.myCartList[index].amount;

      if (this.myCartList[index].info.premark !== '電話卡') {
        this.totalCartPriceWithoutCard -= this.myCartList[index].totalPrice;
      }

      this.myCartList.splice(index, 1);
    }

    this.totalCartPriceWithoutCard$.next(this.totalCartPriceWithoutCard);
    this.totalCartSort$.next(this.myCartList.length);
    this.totalCartPrice$.next(this.totalCartPrice);
    this.totalProductAmount$.next(this.totalProductAmount);
    this.myCartList$.next(this.myCartList);

    this.deleteCartProdcutFromServer(pid, userInfo);
  }

  deleteCartProdcutFromServer(productId, userInfo) {
    this.api.apost('tpdmod/delete', {userInfo, productId}).subscribe((res: any) => {
      console.log('********** Set Cart List To Server **********');
      console.log(res);
    })
  }

  getCart(): Observable<Array<CART_PRODUCT>> {
    return this.myCartList$;
  }

  getTotalCartPriceWithoutCard(): Observable<number> {
    return this.totalCartPriceWithoutCard$;
  }

  getTotalCartPrice(): Observable<number> {
    return this.totalCartPrice$;
  }

  getTotalCartSort(): Observable<number> {
    return this.totalCartSort$;
  }

  getTotalProductAmount(): Observable<number> {
    return this.totalProductAmount$;
  }

  clearCart() {
    this.myCartList = [];
    this.myCartList$.next([]);

    this.totalCartPrice = 0;
    this.totalCartPrice$.next(0);

    this.totalProductAmount = 0;
    this.totalProductAmount$.next(0);

    this.totalCartSort$.next(0);
  }

}
