import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule, MatButtonModule, MatMenuModule, MatIconModule, MatSidenavModule, MatProgressBarModule } from '@angular/material';
import { MatDividerModule } from '@angular/material/divider';
import { ImgLazyLoadDirective } from './../../directives/img-lazy-load.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatProgressBarModule,
    // Directives
    ImgLazyLoadDirective
  ],
  declarations: [
    ImgLazyLoadDirective,
  ]
})
export class SharedMaterialModule { }
