import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { FileService } from '../../../../services/file.service';
import { ApiService } from '../../../../services/api.service';
import { Response } from './../../../../models/response';
import { Subject, of } from '../../../../../../node_modules/rxjs';
import { switchMap } from '../../../../../../node_modules/rxjs/operators';

const _UploadFileDefaultName_ = '未選擇檔案';

@Component({
  selector: 'app-batch-upload-dialog',
  templateUrl: './batch-upload-dialog.component.html',
  styleUrls: ['./batch-upload-dialog.component.scss']
})
export class BatchUploadDialogComponent implements OnInit {
  loading = false;

  // Parameters
  vendorId = '';
  vendorName = '';

  // Uploads
  @ViewChild('fileElm') fileElm: ElementRef;
  @ViewChild('uploadElm') uploadElm: ElementRef;
  uploadFileName = _UploadFileDefaultName_;
  uploadStatus = 0; // 0: no upload; 1: start upload; 2: end upload


  // 進度條
  currentProgres: any = 0;

  validList = [];
  invalidList = [];
  repeatList = [];
  newList = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<BatchUploadDialogComponent>,
    private fileService: FileService,
    private api: ApiService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.vendorId = this.data.vId;
    this.vendorName = this.data.vName;
    console.log(this.vendorId);
    console.log(this.vendorName);
  }

  /***** Buttons ******/
  uploadFileBtn() {
    console.log('click upload btn');
    this.fileElm.nativeElement.click();
    console.log('go upload file');
  }
  closeDialog(data = '') {
    this.dialogRef.close(data);
  }
  upload() {
    console.log(this.validList);
    this.uploadStatus = 1;
    this.currentProgres = 0;

    // 抓取目前上傳進度
    const progress = new Subject<any>();
    progress.pipe(
      switchMap((e) => {
        console.log(e);
        if (e) {
          return this.api.getAll('tables/pdma/batch/progress');
        } else {
          return of(false);
        }
      })
    ).subscribe((res: Response) => {
      console.log(res);
      if (res.payload) {
        this.currentProgres = (res.payload * 100).toFixed(2);
      }
    });
    const progressInterval = setInterval(() => { progress.next(true); }, 1000);

    this.api.post('pdma/insert/batch', {
      productList: this.validList, vId: this.vendorId, vName: this.vendorName
    }).subscribe((res: Response) => {
      console.log(res);
      this.repeatList = res.payload.repeatList;
      this.newList = res.payload.newList;
      this.uploadStatus = 2;

      clearInterval(progressInterval);
      progress.next(false);
      progress.unsubscribe();
      this.currentProgres = 100;
    });
  }

  /***** Functions *****/
  incomingfile(event) {
    console.log('uploading file');
    const fileName = event.target.value;
    const isFileExtValid = checkfile(fileName);
    if (isFileExtValid) {
      this.uploadFileName = fileName.substring(fileName.lastIndexOf('\\')).slice(1);
      this.fileService.XlsxToJson(event.target.files[0]).then(data => {
        console.log(data);

        this.loading = true;
        this.validList = [];
        this.invalidList = [];
        this.repeatList = [];
        this.uploadStatus = 0;
        // 判斷是否有空值
        data.forEach((elm, index) => {
          const titles = Object.keys(elm);
          // elm['row'] = index + 1;
          if (!titles.includes('品號')) {
            this.invalidList.push(elm);
          } else {
            this.validList.push(elm);
          }
        });

        console.log(this.validList);
        console.log(this.invalidList);
        this.loading = false;
      });
    } else {
      event.target.value = '';
      console.log(this.fileElm.nativeElement.value);
      this.uploadFileName = _UploadFileDefaultName_;
    }
  }
  // exportToExcel(data: any[], fileName = '商品更新清單') {
  //   this.fileService.ArrayToExcel(data, fileName);
  // }

}

function checkfile(fileName: string) {
  const validExts = new Array('.xlsx', '.xls', '.csv');
  const fileExt = fileName.substring(fileName.lastIndexOf('.'));
  console.log(fileExt);
  if (validExts.indexOf(fileExt) < 0) {
    alert('檔案類型錯誤，可接受的副檔名有：' + validExts.toString());
    return false;
  }
  return true;
}
