<h4 class="text-center mb-3">選擇類別</h4>
<form [formGroup]="myForm" (ngSubmit)="save(myForm.getRawValue())">
  <div class="form-inline cus-mb-input">
    <div class="col-12 p-0 form-group">
      <label class="label-input">第一層類別</label>
      <div class="col p-0">
        <select #formElm class="form-control form-control-sm w-100"
          [ngClass]="{'error-input':!myForm.controls['dma10'].valid && (myForm.controls['dma10'].touched || isSaveBtnClicked)}"
          [formControl]="myForm.controls['dma10']"
          [(ngModel)]="selectedLevel1Type"
          (ngModelChange)="onDMA10Changes()">
          <option value="">-----</option>
          <option *ngFor="let type of level1TypeList;" [value]="type.dmk04">{{ type.dmk04 }}</option>
        </select>
        <small *ngIf="myForm.controls['dma10'].hasError('required') && (myForm.controls['dma10'].touched || isSaveBtnClicked)" class="error-feedback">
          {{ 'error.require' | translate }}</small>
      </div>
    </div>
  </div>

  <div class="form-inline cus-mb-input">
    <div class="col-12 p-0 form-group">
      <label class="label-input">第二層類別</label>
      <div class="col p-0">
        <select #formElm class="form-control form-control-sm w-100"
          [ngClass]="{'error-input':!myForm.controls['dma48'].valid && (myForm.controls['dma48'].touched || isSaveBtnClicked)}"
          [formControl]="myForm.controls['dma48']"
          [(ngModel)]="selectedLevel2Type"
          (ngModelChange)="onDMA48Changes()">
          <option value="">-----</option>
          <option *ngFor="let type of level2TypeList;" [value]="type.dmk04">{{ type.dmk04 }}</option>
        </select>
        <small *ngIf="myForm.controls['dma48'].hasError('required') && (myForm.controls['dma48'].touched || isSaveBtnClicked)" class="error-feedback">
          {{ 'error.require' | translate }}</small>
      </div>
    </div>
  </div>

  <div class="form-inline cus-mb-input">
    <div class="col-12 p-0 form-group">
      <label class="label-input">第三層類別</label>
      <div class="col p-0">
        <select #formElm class="form-control form-control-sm w-100"
          [ngClass]="{'error-input':!myForm.controls['dma50'].valid && (myForm.controls['dma50'].touched || isSaveBtnClicked)}"
          [formControl]="myForm.controls['dma50']"
          [(ngModel)]="selectedLevel3Type"
          (ngModelChange)="onDMA50Changes()">
          <option value="">-----</option>
          <option *ngFor="let type of level3TypeList;" [value]="type.dmk04">{{ type.dmk04 }}</option>
        </select>
        <small *ngIf="myForm.controls['dma50'].hasError('required') && (myForm.controls['dma50'].touched || isSaveBtnClicked)" class="error-feedback">
          {{ 'error.require' | translate }}</small>
      </div>
    </div>
  </div>
  <div class="col-12 text-center mt-3">
    <button type="submit" class="btn btn-primary">確定</button>
  </div>
</form>
