<!-- <app-front-navbar *ngIf="(webMode$ | async) === 0"></app-front-navbar>
<app-navbar *ngIf="(webMode$ | async) === 1"></app-navbar>
<router-outlet></router-outlet> -->

<ng-container *ngIf="!(isAppCalling$ | async)">
  <div *ngIf="(webMode$ | async) === 0" style="width:100%; height: 56px!important;"></div>
  <app-front-navbar *ngIf="(webMode$ | async) === 0" (sidenavToggle)="sidenav.toggle()"></app-front-navbar>
  <app-navbar *ngIf="(webMode$ | async) === 1"></app-navbar>
  <mat-progress-bar *ngIf="globalService.loading" 
    color="grey" mode="indeterminate" 
    style="position: fixed; top: 0; z-index: 999999;">
  </mat-progress-bar>
</ng-container>
<div id="myAppContent" [class.cus-position-absolute]="!(isDoucmentHeightOver$ | async)">
  <router-outlet></router-outlet>
</div>

<!-- Loading -->
<ngx-loading [show]="pageLoading$ | async"></ngx-loading>

<!--
<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
    <!--this is a place for us to add side-nav code
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-progress-bar *ngIf="globalService.loading" color="grey" mode="indeterminate" style="position: fixed; top: 0; z-index: 999999;"></mat-progress-bar>
    <!--in here all the content must reside. We will add a navigation header as well
    <app-front-navbar *ngIf="(webMode$ | async) === 0" (sidenavToggle)="sidenav.toggle()"></app-front-navbar>
    <app-navbar *ngIf="(webMode$ | async) === 1"></app-navbar>
    <main>
      <div id="myAppContent">
        <router-outlet></router-outlet>
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
-->
