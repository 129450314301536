import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Config
import { appConfig } from './../app.config';
// Models
import { Login, Response, User, FrontUser } from './../models';
// Rxjs
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
// Services
import { UtilsService } from './utils.service';
import { ApiService } from './api.service';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /** Backend **/
  loginStatus$ = new BehaviorSubject<boolean>(false);
  currentUser$ = new BehaviorSubject<User>(null);
  // 臨時硬塞進去，找時間重整
  currentUser: User;

  /** Frontend **/
  f_loginStatus$ = new BehaviorSubject<boolean>(false);
  f_currentUser$ = new BehaviorSubject<FrontUser>(null);
  f_currentUser: FrontUser;
  f_guestID = '';

  constructor(
    private cartServices: CartService,
    private apiService: ApiService,
    private http: HttpClient,
    private utilsService: UtilsService,
  ) {}

  /***** Backend 登入 *****/
  login(loginData: Login): Observable<boolean> {
    return this.loginServer(loginData)
    .pipe(
      map((res: Response) => {
        if (res.success) {
          if (loginData.rememberMe) {
            this.utilsService.writeToken(res.payload);
          }
          this.loginStatus$.next(true);
          this.getUser();
          return true;
        } else {
          alert(res.payload);
          return false;
        }
      })
    );
  }
  loginServer(loginData: Login): Observable<Response> {
    const SMI05 = loginData.SMI05.trim();
    const SMI06 = loginData.SMI06.trim();
    return this.http.post<Response>(appConfig.apiUrl + '/api/users/login', {SMI05, SMI06});
  }
  logout() {
    this.loginStatus$.next(false);
    this.currentUser$.next(null);
    this.utilsService.removeToken();
  }
  getUser() {
    this.getUserFromServer().subscribe((res: User) => {
      console.log('==========Res==========');
      console.log(res);
      this.currentUser = res || null;
      this.currentUser$.next(res);
    });
  }
  getUserFromServer(): Observable<any> {
    if (!this.utilsService.isTokenExpired()) {
      const token = this.utilsService.getToken();
      console.log(token);
      return this.http
      .post(appConfig.apiUrl + '/api/users/currentUser', { token })
      .pipe(
        map((res: Response) => {
          if (res.success) {
              return res.payload;
          } else {
              return null;
          }
        })
      );
    } else {
      return of(null);
    }
  }
  getLoginStatus(): Observable<boolean> {
    return this.loginStatus$;
  }
  getCurrentUser(): Observable<User> {
    return this.currentUser$;
  }
  checkUser(): Observable<boolean> {
    if (!this.utilsService.isTokenExpired()) {
      this.loginStatus$.next(true);
      this.getUser();
      return of(true);
    } else {
      this.utilsService.removeToken();
      return of(false);
    }
  }

  /***** Frontend 登入 *****/
  f_login(loginData): Observable<boolean> {
    return this.f_loginServer(loginData)
    .pipe(
      map((res: Response) => {
        if (res.success) {
          this.utilsService.writeToken(res.payload, '', 'frontend');
          this.f_loginStatus$.next(true);
          this.f_getUser();
          return true;
        } else {
          // alert(res.payload);
          return false;
        }
      })
    );
  }
  f_loginServer(loginData): Observable<Response> {
    const smi05 = loginData.mma11.trim();
    const smi06 = loginData.mma26.trim();
    return this.http.post<Response>(appConfig.apiUrl + '/api/users/front/login', {smi05, smi06});
  }

  f_register(registerData): Observable<boolean> {
    return this.f_registerServer(registerData)
    .pipe(
      map((res: Response) => {
        console.log(res);
        if (res.success) {
          this.utilsService.writeToken(res.payload, '', 'frontend');
          this.f_loginStatus$.next(true);
          this.f_getUser();
          return true;
        } else {
          alert(res.payload);
          return false;
        }
      })
    );
  }
  f_registerServer(registerData) {
    return this.apiService.fpostBlob('users/front/register', registerData, false);
  }

  f_logout() {
    this.f_loginStatus$.next(false);
    this.f_currentUser$.next(null);
    this.utilsService.removeToken('', 'frontend');
 
    if (!localStorage.getItem('guestID')) {
      this.f_guestID = this.createGuestID();
      localStorage.setItem('guestID', this.f_guestID);
    }
  }
  f_getUser() {
    this.f_getUserFromServer().subscribe((res: FrontUser) => {
      console.log('==========Res==========');
      console.log(res);
      this.f_currentUser = res || null;
      this.f_currentUser$.next(res);
      // Clear Global Guest
      if (localStorage.getItem('guestID')) {
        localStorage.removeItem('guestID');
      }
      // Set Cartlist
      this.cartServices.initCart(this.f_currentUser.userInfo.mma04, '', this.f_currentUser.userInfo);
    });
  }
  f_getUserFromServer(): Observable<any> {
    if (!this.utilsService.isTokenExpired('', 'frontend')) {
      const token = this.utilsService.getToken('', 'frontend');
      return this.http
      .post(appConfig.apiUrl + '/api/users/front/currentUser', { token })
      .pipe(
        map((res: Response) => {
          if (res.success) {
              return res.payload;
          } else {
              return null;
          }
        })
      );
    } else {
      return of(null);
    }
  }

  f_getLoginStatus(): Observable<boolean> {
    return this.f_loginStatus$;
  }
  f_getCurrentUser(): Observable<FrontUser> {
    return this.f_currentUser$;
  }
  f_checkUser(): Observable<boolean> {
    if (!this.utilsService.isTokenExpired('', 'frontend')) {
      // console.log('token exits');
      this.f_loginStatus$.next(true);
      this.f_getUser();
      return of(true);
    } else {
      // console.log('token expired');
      this.utilsService.removeToken('', 'frontend');
      this.f_logout();
      return of(false);
    }
  }
  createGuestID(): string {
    // 五碼隨機字母/數字
    return Math.random().toString(36).substr(2, 5);
  }

}
