
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { Router } from '../../../../node_modules/@angular/router';
import { HttpClient } from '@angular/common/http';
// Consts
import { appPath } from './../../app-path.const';
import { appConfig } from '../../app.config';
import { Response } from './../../models/response';
// Rxjs
import { Observable, Subscription, Subject } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
// Services
import { LanguageService } from '../../services/language.service';
import { FrontGlobalService } from '../../services/front-global.service';
import { ImgService } from '../../services/img.service';
import { CartService } from './../../services/cart.service';
import { GlobalService } from './../../services/global.service';
import { UserService } from './../../services/user.service';
import { MytoastrService } from './../../services/mytoastr.service';

@Component({
  selector: 'app-front-navbar',
  templateUrl: './front-navbar.component.html',
  styleUrls: ['./front-navbar.component.scss']
})
export class FrontNavbarComponent implements OnInit, OnDestroy {

  @Output() public sidenavToggle = new EventEmitter();

  f_login$: Observable<boolean>;
  f_user$: Observable<any>;
  flag$: Observable<string>;
  languageList = [];
  cartAmount$: Observable<number>;

  language$: Subscription;
  currentLanguage = '';

  f_site_settings$: Subscription;
  f_site_settings: any;

  searchValue = '';
  private getSerachResut$ = new Subject<any>();
  searchResultList = [];
  isSeraching = false;
  isSearchBoxFocus = false;

  countryList = [];
  brandFilterList = [];
  selectedFilterCountry = '';
  selectedFilterBrand = '';

  isOpenMoreCondition = false;

  constructor(
    private cartService: CartService,
    private global: GlobalService,
    private http: HttpClient,
    public imgService: ImgService,
    private userService: UserService,
    public myToastr: MytoastrService,
    private frontGlobalService: FrontGlobalService,
    private router: Router,
    private languageService: LanguageService
  ) {

    this.f_login$ = this.userService.f_getLoginStatus();
    this.f_user$ = this.userService.f_getCurrentUser();
    this.flag$ = this.languageService.getCurrentFlag();
    this.languageList = this.languageService.translate.langs;
    this.cartAmount$ = this.cartService.getTotalProductAmount();
    this.f_site_settings$ = this.frontGlobalService.getSiteSettings().subscribe(res => {
      // console.log(res);
      this.f_site_settings = res;
      // console.log(this.f_site_settings);
    });

    this.language$ = this.languageService.getCurrentLanguage().subscribe(res => {
      if (res) {
        this.currentLanguage = res.lang;
      }
    });

    this.getSerachResut$.pipe(
      switchMap((e) => {
        if (e) {
          this.isSeraching = true;
          return this.http.post(appConfig.apiUrl + '/api/front/product/search',
            { lang: this.currentLanguage, text: this.searchValue, country: this.selectedFilterCountry, brand: this.selectedFilterBrand });
        }
      })
    ).subscribe((res: Response) => {
      if (res.success) {
        this.isSeraching = false;
        this.searchResultList = res.payload;
        console.log(this.searchResultList);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.f_site_settings$.unsubscribe();
    this.language$.unsubscribe();
  }

  searchProduct(e) {
    this.searchValue = e.target.value;
    console.log(this.searchValue);
    if (this.searchValue) {
      this.getSerachResut$.next(true);
    }
  }

  moreCondition() {
    this.http.get(appConfig.apiUrl + '/api/front/country/list').subscribe((res: Response) => {
      if (res.success) {
        console.log(res);
        this.countryList = res.payload;
      }
    });
  }

  onCountryChange() {
    if (this.selectedFilterCountry) {
      this.http.get(appConfig.apiUrl + '/api/front/filter/brand/list/' + this.selectedFilterCountry).subscribe((res: Response) => {
        if (res.success) {
          console.log(res);
          this.brandFilterList = res.payload;
        }
      });
    }
    this.getSerachResut$.next(true);
  }

  onBrandChange() {
    this.getSerachResut$.next(true);
  }

  goProduct(product) {
    this.global.toggleLoading(true);
    this.router.navigate([appPath.front.product, product.pid]);
  }

  // public onToggleSidenav = () => {
  //   this.sidenavToggle.emit();
  // }

  goFrontend() {
    // this.global.toggleLoading(true);
    this.router.navigate(['/' + appPath.front.home]);
  }

  async goCart() {
    const cartAmount = await this.cartService.getTotalProductAmount().pipe(take(1)).toPromise();
    if (cartAmount > 0) {
      this.router.navigate(['/' + appPath.front.cart]);
    } else {
      this.myToastr.error(23);
    }
  }

  goFrontLogin() {
    console.log('login');
    this.router.navigate(['/' + appPath.front.login]);
  }

  goFrontRegister() {
    console.log('register');
    this.router.navigate(['/' + appPath.front.register]);
  }

  goMemberCenter() {
    this.router.navigate(['/' + appPath.front.memberCenter]);
  }

  logout() {
    this.userService.f_logout();
    this.router.navigate(['/' + appPath.front.home]);
    this.myToastr.success(33);
  }

  goBackend() {
    this.router.navigate(['/' + appPath.login]);
  }

  getFlag(language: string) {
    return this.languageService.countryMap.get(language);
  }

  changeLanguage(language: string) {
    this.languageService.setLang(language);
  }

}
