import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
// Config
import { appPath } from './../app-path.const';
// Services
import { UserService } from './user.service';
import { UtilsService } from './utils.service';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(
    private injector: Injector,
    private utilsService: UtilsService,
    private userService: UserService,
    private cartService: CartService,
  ) {}

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadFrontUser();
      return this.userService.checkUser().subscribe(res => {
        if (res) {
          setInterval(() => {
            this.checkStatus();
          }, 1000 * 60 * 5); // check current status every 5 min
          this.login();
        } else {
          this.logout();
        }
        resolve(res);
      }, err => {
          console.log(err);
          reject(err);
      });
    });
  }

  checkStatus() {
    if (this.utilsService.isTokenExpired()) {   // if token expired
      this.logout();
      console.log('logout due to token expired');
    }
  }

  //  Startup 時無法直接用 DI (Depency Injection) Router, 需要用到Injector 來導入Router
  login() {
    const router = this.injector.get(Router);
    router.navigate([appPath.front.home]);
  }
  logout() {
    this.userService.logout();
    // const router = this.injector.get(Router);
    // router.navigate(['/']);
  }

  /**** Frontend ****/
  loadFrontUser() {
    this.userService.f_checkUser().subscribe(res => {
      if (res) {
        setInterval(() => {
          this.f_checkStatus();
        }, 1000 * 60 * 5); // check current status every 5 min
      } else {
        if (localStorage.getItem('guestID')) {
          this.userService.f_guestID = localStorage.getItem('guestID');
          this.cartService.initCart('', this.userService.f_guestID);
        }
      }
    });
  }
  f_checkStatus() {
    if (this.utilsService.isTokenExpired('', 'frontend')) {   // if token expired
      this.userService.f_logout();
      console.log('logout due to token expired');
    }
  }

}
