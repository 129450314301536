import { UserService } from './user.service';
import { GlobalService } from './global.service';
import { Injectable } from '@angular/core';

import { UA, InviteServerContext, InviteClientContext } from 'sip.js';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { take } from '../../../node_modules/rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SipService {
  // SIP
  b_userAgent: UA;
  private b_session: InviteServerContext;
  ringAudio: HTMLAudioElement;
  b_acceptedCallTimeOut: any;
  b_isAcceptedCall = false;
  b_isAcceptedCall$ = new BehaviorSubject<boolean>(false);
  commingCall = false;
  commingCall$ = new BehaviorSubject<any>(null); // 包含撥話者資訊

  f_userAgent: UA;
  private f_session: InviteClientContext;
  // waitAudio: HTMLAudioElement = new Audio('../assets/sounds/call-waiting.mp3');
  waitAudio: HTMLAudioElement;
  f_acceptedCallTimeOut: any;
  f_isAcceptedCall = false;
  f_isAcceptedCall$ = new BehaviorSubject<boolean>(false);
  isCalling$ = new BehaviorSubject<boolean>(false);

  // Status

  constructor(
    private globalService: GlobalService,
    private userService: UserService,
  ) {
    // combineLatest(
    //   this.userService.getLoginStatus(),
    //   this.globalService.getWebMode()
    // ).subscribe(res => {
    //   console.log(res);
    //   const b_loginStatus = res[0];
    //   const webMode = res[1];
    //   if (b_loginStatus && webMode === 1) {
    //     this.backendSipStart();
    //   } else {
    //     if (this.b_userAgent) {
    //       this.backendSipOff();
    //     }
    //   }
    // });
  }

  backendSipStart(sipAccount) {
    /***** 設定Agent資訊 *****/
    // this.b_userAgent = new UA({
    //   uri: 'gowint@gowint.onsip.com',
    //   authorizationUser: 'gowint',
    //   password: 'mKLYF6fS9STDZVEB',
    // });
    console.log(sipAccount);
    this.b_userAgent = new UA({
      uri: sipAccount.uri,
      authorizationUser: sipAccount.authorizationUser,
      password: sipAccount.password,
    });
    this.b_userAgent.start();

    /***** 初始化來電鈴聲 *****/
    this.ringAudio = new Audio('../assets/sounds/call-ringing.mp3');

    /***** 等別人打進來 *****/
    this.b_userAgent.on('invite', (session) => {
      this.b_session = session;

      /*** 播放來電鈴聲 ***/
      this.playAudio(this.ringAudio, 'backend');

      /*** 來電者資訊 ***/
      const callerInfo = {
        role: session.request.getHeaders('role')[0],
        uid: session.request.getHeaders('uid')[0],
        uname: session.request.getHeaders('uname')[0],
        source: session.request.getHeaders('source')[0],
        language: session.request.getHeaders('lang')[0],
        osid: session.request.getHeaders('osid')[0],
      };
      console.log(callerInfo);

      /*** 回寫全域變數 ***/
      this.commingCall = true;
      this.commingCall$.next(callerInfo);

      /*** 監聽客服接起電話 ***/
      const remoteVideo: any = document.createElement('video');
      const localVideo: any = document.createElement('video');
      this.b_session.on('accepted', () => {
        const pc = this.b_session.sessionDescriptionHandler.peerConnection;

        // Gets remote tracks
        const remoteStream = new MediaStream();
        pc.getReceivers().forEach(function(receiver) {
          remoteStream.addTrack(receiver.track);
        });
        remoteVideo.srcObject = remoteStream;
        remoteVideo.play();

        // Gets local tracks
        const localStream = new MediaStream();
        pc.getSenders().forEach(function(sender) {
          localStream.addTrack(sender.track);
        });
        localVideo.srcObject = localStream;
        localVideo.play();
        localVideo.muted = true;
      });
    });

    this.b_userAgent.on('transactionDestroyed', () => { // 來電中止 (尚未找到適合的event)
      if (this.commingCall && !this.b_isAcceptedCall) {
        this.stopAudio(this.ringAudio, 'backend');
        this.commingCall = false;
        this.commingCall$.next(null);
        this.b_isAcceptedCall = false;
        this.b_isAcceptedCall$.next(false);
        alert('通話已結束');
      }
    });
  }

  answer() {
    this.b_isAcceptedCall = true;
    this.b_isAcceptedCall$.next(true);

    console.log('=======================================');
    console.log(this.b_isAcceptedCall);

    this.stopAudio(this.ringAudio, 'backend');

    const options = {
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false
        },
      }
    };

    /***** 接起電話 *****/
    this.b_session.accept(options);

    /*** 監聽電話掛斷 ***/
    this.b_session.on('terminated', () => {
      if (this.commingCall) {
        this.stopAudio(this.ringAudio, 'backend');
        this.commingCall = false;
        this.commingCall$.next(null);
        this.b_isAcceptedCall = false;
        this.b_isAcceptedCall$.next(false);
        alert('通話已結束');
      }
    });
  }

  backendSipOff() {
    if (this.b_userAgent) {
      this.b_userAgent.stop();
    }
  }

  call(myExtraHeaders = [], uri) {
    /***** 初始化撥話鈴聲 *****/
    this.waitAudio = new Audio('../assets/sounds/call-waiting.mp3');

    /***** 播放電話鈴聲 *****/
    this.playAudio(this.waitAudio, 'frontend');

    /***** 回寫全域變數 *****/
    this.isCalling$.next(true);

    /***** 打給客服 *****/
    this.f_userAgent = new UA();
    const options = {
      extraHeaders: myExtraHeaders,
      sessionDescriptionHandlerOptions: {
        constraints: { audio: true, video: false },
      }
    };
    // this.f_session = this.f_userAgent.invite('gowint@gowint.onsip.com', options);
    this.f_session = this.f_userAgent.invite(uri, options);

    /***** 監聽客服接起電話 *****/
    const remoteVideo: any = document.createElement('video');
    const localVideo: any = document.createElement('video');
    this.f_session.on('accepted', () => {
      const pc = this.f_session.sessionDescriptionHandler.peerConnection;
      // Gets remote tracks
      const remoteStream = new MediaStream();
      pc.getReceivers().forEach(function(receiver) {
        remoteStream.addTrack(receiver.track);
      });
      remoteVideo.srcObject = remoteStream;
      remoteVideo.play();
      // remoteVideo.volume = 0.2;

      // Gets local tracks
      const localStream = new MediaStream();
      pc.getSenders().forEach(function(sender) {
        localStream.addTrack(sender.track);
      });
      localVideo.srcObject = localStream;
      localVideo.play();
      localVideo.muted = true;

      this.f_isAcceptedCall = true;
      this.f_isAcceptedCall$.next(true);
      this.stopAudio(this.waitAudio, 'frontend');
    });

    this.f_session.on('terminated', () => {
      this.stopAudio(this.waitAudio, 'frontend');
      this.isCalling$.next(false);
      this.f_isAcceptedCall = false;
      this.f_isAcceptedCall$.next(false);
      this.f_userAgent.stop();
      alert('通話已結束');
    });
  }

  hangup(type) {

    if (type === 'frontend') { // front end
      this.f_session.terminate();
    } else { // back end
      this.b_session.terminate();
    }

  }

  playAudio(audio: HTMLAudioElement, type) {
    if (type === 'frontend') {
      audio.addEventListener('ended', () => {
        this.f_acceptedCallTimeOut = setTimeout(() => {
          if (!this.f_isAcceptedCall) {
            audio.currentTime = 0;
            audio.play();
          }
        }, 1000);
      }, false);
    } else {
      audio.addEventListener('ended', () => {
        this.b_acceptedCallTimeOut = setTimeout(() => {
          if (!this.b_isAcceptedCall) {
            audio.currentTime = 0;
            audio.play();
          }
        }, 1000);
      }, false);
    }
    audio.play();
  }

  stopAudio(audio: HTMLAudioElement, type) {
    if (type === 'frontend') {
      clearTimeout(this.f_acceptedCallTimeOut);
    } else {
      clearTimeout(this.b_acceptedCallTimeOut);
    }
    audio.pause(); // 停止音樂
    audio.currentTime = 0; // 將音樂時間點設為0
  }

  getCommingStatus() {
    return this.commingCall$;
  }
  setCommingStatus(callerInfo) { // 訪客註冊為會員後，將會員資料塞入callerInfo中
    this.commingCall$.next(callerInfo);
  }

  getBackAcceptedCallStatus() {
    return this.b_isAcceptedCall$;
  }

  getCallingStatus() {
    return this.isCalling$;
  }

  getFrontAcceptedCallStatus() {
    return this.f_isAcceptedCall$;
  }
}
