import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MytoastrService {

  column_translate = {};

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    this.translate.get('column').subscribe(res => {
      // console.log(res);
      this.column_translate = res;
    });
  }

  /** Button Modes
   * 0: view
   * 1: add
   * 2: revise
   */

  success(btnMode) {
    switch (btnMode) {
      case 1:
        this.toastr.success('新增成功', '', { timeOut: 2000 });
        break;
      case 2:
        this.toastr.success('修改成功', '', { timeOut: 2000 });
        break;
      case 3:
        this.toastr.success('刪除成功', '', { timeOut: 2000 });
        break;
      case 4:
        this.toastr.success('複製成功', '', { timeOut: 2000 });
        break;
      case 5:
        this.toastr.success('選擇成功', '', { timeOut: 2000 });
        break;
      case 21:
        this.toastr.success('成功加到購物車', '', { timeOut: 2000 });
        break;
      case 31:
        this.toastr.success('註冊成功', '', {timeOut: 2000});
        break;
      case 32:
        this.toastr.success('登入成功', '', {timeOut: 2000});
        break;
      case 33:
        this.toastr.success('登出成功', '', {timeOut: 2000});
        break;
      case 34:
        this.toastr.success('商品刪除成功', '', {timeOut: 2000});
        break;
      case 35:
        this.toastr.success('訂單成立成功', '', {timeOut: 2000});
        break;
      case 41:
        this.toastr.success('推撥發送成功', '', {timeOut: 2000});
        break;
      case 51:
        this.toastr.success('申報成功', '', {timeOut: 2000});
        break;
      case 52:
        this.toastr.success('訂單完成', '', {timeOut: 2000});
        break;
      case 53:
        this.toastr.success('訂單取消', '', {timeOut: 2000});
        break;
    }
  }

  error(mode, errMsg = '') {
    switch (mode) {
      case 1:
        this.toastr.error('無此權限', '', { timeOut: 2000 });
        break;
      case 2:
        this.toastr.error('取消刪除動作', '', { timeOut: 2000 });
        break;
      case 99:
        this.toastr.error(' ' + errMsg, '', { timeOut: 2000 });
        break;
      case 22:
        this.toastr.error('商品數量不得小於1', '', { timeOut: 2000 });
        break;
      case 23:
        this.toastr.error('無任何商品加到購物車', '', { timeOut: 2000 });
        break;
      case 31:
        this.toastr.error('註冊失敗', '', {timeOut: 2000});
        break;
      case 32:
        this.toastr.error('帳號或密碼錯誤', '登入失敗', {timeOut: 2000});
        break;
      case 33:
        this.toastr.error('登出失敗', '', {timeOut: 2000});
        break;
      case 34:
        this.toastr.error('商品刪除失敗', '', {timeOut: 2000});
        break;
      case 35:
        this.toastr.error('訂單成立失敗', '', {timeOut: 2000});
        break;
      case 41:
        this.toastr.error('推撥發送失敗', '', {timeOut: 2000});
        break;
      case 42:
        this.toastr.error('請輸入推播訊息', '', {timeOut: 2000});
        break;
      case 51:
        this.toastr.error('至少勾選一筆資料', '', {timeOut: 2000});
        break;
      case 52:
        this.toastr.error('請輸入運輸單號', '', {timeOut: 2000});
        break;
      case 53:
        this.toastr.error('請選擇貨運公司', '', {timeOut: 2000});
        break;
    }
  }

}
