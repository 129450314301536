import { switchMap } from 'rxjs/operators';
import { Response } from './../../../models/response';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Subject, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-search-product-dialog',
  templateUrl: './search-product-dialog.component.html',
  styleUrls: ['./search-product-dialog.component.scss']
})
export class SearchProductDialogComponent implements OnInit, OnDestroy {

  allSortList = [];
  level1List = [];
  level2List = [];
  level3List = [];
  level1SelectedValue = ''; // dmk03
  level2SelectedValue = ''; // dmk03
  level3SelectedValue = ''; // dmk03

  productList$ = new Subject<Array<any>>();
  productList$$: Subscription;
  productList = [];
  isProduct = true;

  selected = -1;
  selectedProduct: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SearchProductDialogComponent>,
    private apiService: ApiService,
  ) {
    this.apiService.getList('pdmk', {table: 'pdmk'}).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.allSortList = res.data;
        this.level1List = this.allSortList.filter(e => e.dmk11 === '01');
      }
    });
    this.productList$$ = this.productList$.pipe(
      switchMap((searchSortList) => {
        return this.apiService.post('view/vpdm01', { condition: searchSortList});
      })
    ).subscribe((res: Response) => {
      console.log(res);
      this.productList = res.payload;
      if (this.productList.length) {
        this.isProduct = true;
      } else {
        this.isProduct = false;
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.productList$$.unsubscribe();
  }

  selectedSortValueOnChange(level) {
    this.selected = -1;
    this.selectedProduct = null;
    const searchSortList = [];
    if (level === 1) {
      this.level2List = [];
      this.level3List = [];
      this.level2List = this.allSortList.filter(e => (e.dmk10 === this.level1SelectedValue) && (e.dmk11 === '02'));
      searchSortList.push(this.level1SelectedValue);
      // this.allSortList.forEach(e2 => {
      //   if (e2.dmk10 === this.level1SelectedValue) {
      //     searchSortList.push(e2.dmk03);
      //     this.allSortList.forEach(e3 => {
      //       if (e3.dmk10 === e2.dmk03) {
      //         searchSortList.push(e3.dmk03);
      //       }
      //     });
      //   }
      // });
    } else if (level === 2) {
      this.level3List = [];
      this.level3List = this.allSortList.filter(e => (e.dmk10 === this.level2SelectedValue) && (e.dmk11 === '03'));
      searchSortList.push(this.level2SelectedValue);
      // this.allSortList.forEach(e3 => {
      //   if (e3.dmk10 === this.level2SelectedValue) {
      //     searchSortList.push(e3.dmk03);
      //   }
      // });
    } else {
      searchSortList.push(this.level3SelectedValue);
    }
    this.productList$.next(searchSortList);
  }

  closeDialog(data = '') {
    this.dialogRef.close(data);
  }

}
