import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
export const BTOKEN = 'backend_token';
export const FTOKEN = 'frontend_token';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private jwtHelper: JwtHelperService
  ) { }

  isTokenExpired(token: string = '', source: string = ''): boolean {
    if (source === 'frontend') {
      token = FTOKEN;
    } else {
      token = BTOKEN;
    }
    const jwtStr = this.getToken(token, source);
    if (jwtStr) {
      return this.jwtHelper.isTokenExpired(jwtStr);
    } else {
      return true;
    }
  }

  writeToken(value: string, token: string = '', source: string = ''): void {
    if (source === 'frontend') {
      token = FTOKEN;
    } else {
      token = BTOKEN;
    }
    localStorage.setItem(token, value);
  }

  getToken(token: string = '', source: string = ''): any {
    if (source === 'frontend') {
      token = FTOKEN;
    } else {
      token = BTOKEN;
    }
    return localStorage.getItem(token);
  }

  removeToken(token: string = '', source: string = ''): void {
    if (source === 'frontend') {
      token = FTOKEN;
    } else {
      token = BTOKEN;
    }
    if (this.getToken(token, source)) {
      localStorage.removeItem(token);
    }
  }

}
