import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

const BUTTON_DEFAULT = 0;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  loading = false;

  /*** ***/
  site_mode = 0; // 0 上線模式/ 1 測試模式
  back_toggle = {
    message_bell: 0, // 訊息小鈴鐺; 0 關閉/ 1 開啟
  }
  front_toggle = {
    banner: 0,
    product: 0,
    middle_window: 0,
  }

  /*** Page Loading ***/
  pageLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * 0: 前台網站
   * 1: 後台管理介面
   */
  webMode$ = new BehaviorSubject<number>(0);

  /** Button Modes
   * 0: view
   * 1: add
   * 2: revise
   */
  buttonMode$ = new BehaviorSubject<number>(0);

  /**
   * false: document height is not over screen height;
   * true: document height is over screen height;
  */
  isDoucmentHeightOver$ = new BehaviorSubject<boolean>(false);

  constructor() {
    if (this.site_mode === 0) { // 上線
      this.back_toggle.message_bell = 1;
      this.front_toggle.banner = 1;
      this.front_toggle.product = 1;
      this.front_toggle.middle_window = 1;
    } else if (this.site_mode === 1) { // 測試
      this.back_toggle.message_bell = 0;
      this.front_toggle.banner = 0;
      this.front_toggle.product = 1;
      this.front_toggle.middle_window = 0 ;
    }
  }

  toggleLoading(toggle) {
    if (toggle) {
      this.loading = true;
    } else {
      this.loading = false;
    }
  }

  setButtonMode(mode: number) {
    this.buttonMode$.next(mode);
  }
  getButtonMode(): Observable<number> {
    return this.buttonMode$;
  }

  setWebMode(mode: number) {
    this.webMode$.next(mode);
  }
  getWebMode(): Observable<number> {
    return this.webMode$;
  }

  setPageLoading(val: boolean) {
    this.pageLoading$.next(val);
  }
  getPageLoading(): Observable<boolean> {
    return this.pageLoading$;
  }

  setIsDoucmentHeightOver(mode: boolean) {
    this.isDoucmentHeightOver$.next(mode);
  }
  getIsDoucmentHeightOver(): Observable<boolean> {
    return this.isDoucmentHeightOver$;
  }

}
