import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Response } from './../../../../models/response';
import { ApiService } from './../../../../services/api.service';

@Component({
  selector: 'app-copy-dialog',
  templateUrl: './copy-dialog.component.html',
  styleUrls: ['./copy-dialog.component.scss']
})
export class CopyDialogComponent implements OnInit {
  loading = false;

  title = '產品資訊';
  productTypeList = [];
  selectedproductType = '';

  productList = [];
  selectedproductId = '';
  selectedproduct: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CopyDialogComponent>,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.productTypeList = this.data.productTypes;
  }

  onTypeChange() {
    this.loading = true;
    this.apiService.post('pdma/type', {typeId: this.selectedproductType}).subscribe((res: Response) => {
      console.log(res);
      if (res.success) {
        this.productList = res.payload;
      }
      this.loading = false;
    });
  }

  onProductChange() {
    console.log(this.selectedproductId);
    const tmp = this.productList.find(p => p.dma02.toString() === this.selectedproductId);
    tmp.dma40 = tmp.dma40.toString();
    tmp.dma41 = tmp.dma41.toString();
    this.selectedproduct = tmp;

    console.log(this.selectedproduct);
  }

  closeDialog(data = '') {
    this.dialogRef.close(data);
  }

}
