<button type="btn" class="btn btn-secondary btn-sm" style="float: right;" (click)="closeDialog()"><i class="fas fa-times"></i></button>
<h4 mat-dialog-title class="text-center" style="color: #0056b3;">
  {{ title }}
</h4>

<div class="col-3 my-3 mx-auto">
  <select class="form-control form-control-sm w-100"
    [(ngModel)]="selectedproductType"
    (ngModelChange)="onTypeChange()">
    <option value="" disabled>選擇類別</option>
    <option *ngFor="let type of productTypeList;" [value]="type.dmk03">{{ type.dmk04 }}</option>
  </select>
</div>

<div *ngIf="selectedproductType" class="col-3 my-3 mx-auto">
  <select class="form-control form-control-sm w-100"
    [(ngModel)]="selectedproductId"
    (ngModelChange)="onProductChange()">
    <option value="" disabled>選擇產品</option>
    <option *ngFor="let product of productList;" [value]="product.dma02" [disabled]="product.dma02 === selectedproductId">{{ product.dma04 }}</option>
  </select>
</div>

<div *ngIf="selectedproduct" class="col-8 my-4 mx-auto">
  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <a class="nav-item nav-link active" id="nav-copy-basic-tab" data-toggle="tab" href="#nav-copy-basic" role="tab" aria-controls="nav-copy-basic" aria-selected="true">基本資料</a>
      <a class="nav-item nav-link" id="nav-copy--intro-tab" data-toggle="tab" href="#nav-copy--intro" role="tab" aria-controls="nav-copy--intro" aria-selected="false">產品介紹</a>
      <a class="nav-item nav-link" id="nav-copy-format-tab" data-toggle="tab" href="#nav-copy-format" role="tab" aria-controls="nav-copy-format" aria-selected="false">產品規格</a>
      <a class="nav-item nav-link" id="nav-copy-price-tab" data-toggle="tab" href="#nav-copy-price" role="tab" aria-controls="nav-copy-price" aria-selected="false">產品定價</a>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <!-- 基本資料 -->
    <div class="pt-4 tab-pane fade show active" id="nav-copy-basic" role="tabpanel" aria-labelledby="nav-copy-basic-tab">
      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">產品品名</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" [value]="selectedproduct.dma04" readonly>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">產品品名-印</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" [value]="selectedproduct.dma05" readonly>
          </div>
        </div>
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">產品品名-菲</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" [value]="selectedproduct.dma06" readonly>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">產品品名-越</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" [value]="selectedproduct.dma07" readonly>
          </div>
        </div>
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">產品品名-泰</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" [value]="selectedproduct.dma08" readonly>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">類別名稱</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" [value]="selectedproduct.dma10" readonly>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">出售單位</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder=""  maxlength="4" [value]="selectedproduct.dma11" readonly>
          </div>
        </div>
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">出售數量</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder=""  maxlength="8" [value]="selectedproduct.dma12" readonly>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">包裝單位</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder=""  maxlength="4" [value]="selectedproduct.dma13" readonly>
          </div>
        </div>
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">包裝數量</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder=""  maxlength="8" [value]="selectedproduct.dma14" readonly>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">商品條碼</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" maxlength="15" [value]="selectedproduct.dma15" readonly>
          </div>
        </div>
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">原始條碼</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" maxlength="15" [value]="selectedproduct.dma16" readonly>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">庫存數量</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder=""  maxlength="8" [value]="selectedproduct.dma38" readonly>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-6 p-0 form-group">
          <label class="label-input">促銷商品</label>
          <div class="col p-0">
            <div class="custom-control custom-control-inline">
              <input type="radio" id="prmom_yes" name="promo_control" value="1" class="custom-control-input" [(ngModel)]="selectedproduct.dma40" disabled>
              <label class="custom-control-label" for="prmom_yes">是</label>
            </div>
            <div class="custom-control custom-control-inline">
              <input type="radio" id="promo_no" name="promo_control" value="0" class="custom-control-input" [(ngModel)]="selectedproduct.dma40" disabled>
              <label class="custom-control-label" for="promo_no">否</label>
            </div>
          </div>
        </div>
        <div class="col-6 p-0 form-group">
          <label class="label-input">熱銷商品</label>
          <div class="col p-0">
            <div class="custom-control custom-control-inline">
              <input type="radio" id="hot_yes" name="hot_control" value="1" class="custom-control-input" [(ngModel)]="selectedproduct.dma41" disabled>
              <label class="custom-control-label" for="hot_yes">是</label>
            </div>
            <div class="custom-control custom-control-inline">
              <input type="radio" id="hot_no" name="hot_control" value="0" class="custom-control-input" [(ngModel)]="selectedproduct.dma41" disabled>
              <label class="custom-control-label" for="hot_no">否</label>
            </div>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-12 p-0 form-group">
          <label class="label-input">{{ 'form.remark' | translate }}</label>
          <div class="col p-0">
            <textarea #formElm  class="form-control form-control-sm w-100" placeholder="write something..." rows="3" [value]="selectedproduct.dma42" readonly></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- 產品介紹 -->
    <div class="pt-4 tab-pane fade" id="nav-copy--intro" role="tabpanel" aria-labelledby="nav-copy--intro-tab">
      <div class="form-inline cus-mb-input">
        <div class="col-12 p-0 form-group">
          <label class="label-input">商品介紹</label>
          <div class="col p-0">
            <textarea #formElm  class="form-control form-control-sm w-100" placeholder="write something..." rows="2" [value]="selectedproduct.dma22" readonly></textarea>
          </div>
        </div>
      </div>
      <div class="form-inline cus-mb-input">
        <div class="col-12 p-0 form-group">
          <label class="label-input">商品介紹-印</label>
          <div class="col p-0">
            <textarea #formElm  class="form-control form-control-sm w-100" placeholder="write something..." rows="2" [value]="selectedproduct.dma23" readonly></textarea>
          </div>
        </div>
      </div>
      <div class="form-inline cus-mb-input">
        <div class="col-12 p-0 form-group">
          <label class="label-input">商品介紹-菲</label>
          <div class="col p-0">
            <textarea #formElm  class="form-control form-control-sm w-100" placeholder="write something..." rows="2" [value]="selectedproduct.dma24" readonly></textarea>
          </div>
        </div>
      </div>
      <div class="form-inline cus-mb-input">
        <div class="col-12 p-0 form-group">
          <label class="label-input">商品介紹-越</label>
          <div class="col p-0">
            <textarea #formElm  class="form-control form-control-sm w-100" placeholder="write something..." rows="2" [value]="selectedproduct.dma25" readonly></textarea>
          </div>
        </div>
      </div>
      <div class="form-inline cus-mb-input">
        <div class="col-12 p-0 form-group">
          <label class="label-input">商品介紹-泰</label>
          <div class="col p-0">
            <textarea #formElm  class="form-control form-control-sm w-100" placeholder="write something..." rows="2" [value]="selectedproduct.dma26" readonly></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- 產品規格 -->
    <div class="pt-4 tab-pane fade" id="nav-copy-format" role="tabpanel" aria-labelledby="nav-copy-format-tab">
      <div class="form-inline cus-mb-input">
        <div class="col-12 p-0 form-group">
          <label class="label-input">商品規格</label>
          <div class="col p-0">
            <textarea #formElm  class="form-control form-control-sm w-100" placeholder="write something..." rows="2" [value]="selectedproduct.dma17" readonly></textarea>
          </div>
        </div>
      </div>
      <div class="form-inline cus-mb-input">
        <div class="col-12 p-0 form-group">
          <label class="label-input">商品規格-印</label>
          <div class="col p-0">
            <textarea #formElm  class="form-control form-control-sm w-100" placeholder="write something..." rows="2" [value]="selectedproduct.dma18" readonly></textarea>
          </div>
        </div>
      </div>
      <div class="form-inline cus-mb-input">
        <div class="col-12 p-0 form-group">
          <label class="label-input">商品規格-菲</label>
          <div class="col p-0">
            <textarea #formElm  class="form-control form-control-sm w-100" placeholder="write something..." rows="2" [value]="selectedproduct.dma19" readonly></textarea>
          </div>
        </div>
      </div>
      <div class="form-inline cus-mb-input">
        <div class="col-12 p-0 form-group">
          <label class="label-input">商品規格-越</label>
          <div class="col p-0">
            <textarea #formElm  class="form-control form-control-sm w-100" placeholder="write something..." rows="2" [value]="selectedproduct.dma20" readonly></textarea>
          </div>
        </div>
      </div>
      <div class="form-inline cus-mb-input">
        <div class="col-12 p-0 form-group">
          <label class="label-input">商品規格-泰</label>
          <div class="col p-0">
            <textarea #formElm  class="form-control form-control-sm w-100" placeholder="write something..." rows="2" [value]="selectedproduct.dma21" readonly></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- 產品定價 -->
    <div class="pt-4 tab-pane fade" id="nav-copy-price" role="tabpanel" aria-labelledby="nav-copy-price-tab">
      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">商品定價</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" maxlength="8" [value]="selectedproduct.dma33" readonly>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">商品定價-印</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" maxlength="8" [value]="selectedproduct.dma34" readonly>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">商品定價-菲</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" maxlength="8" [value]="selectedproduct.dma35" readonly>
          </div>
        </div>
      </div>

      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">商品定價-越</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" maxlength="8" [value]="selectedproduct.dma36" readonly>
          </div>
        </div>
      </div>
      <div class="form-inline cus-mb-input">
        <div class="col-sm-6 p-0 form-group">
          <label class="label-input">商品定價-泰</label>
          <div class="col p-0">
            <input #formElm type="text" class="form-control form-control-sm w-100" placeholder="" maxlength="8" [value]="selectedproduct.dma37" readonly>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-2 mx-auto">
  <button *ngIf="selectedproduct" type="button" class="btn btn-primary btn-block" (click)="closeDialog(selectedproduct)">複製</button>
</div>

<ngx-loading [show]="loading"></ngx-loading>
